import { Box, CircularProgress, Pagination, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Join } from "../../components/join/Join";
import SearchComponent from "../../components/search/SearchComponent";
import { useAuth } from "../../firebase/Auth";
import { instance } from "../../utils/axios-config";
import { format } from "date-fns";
import parse from "html-react-parser";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ordenar from "../../assets/ordenar.svg";
import NotificationDetail from "../notifications/NotificationDetail";

export default function Notifications() {

    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [initialLoading, setInitialLoading] = useState(true);
    const [text, setText] = useState();
    const [notifications, setNotifications] = useState([]);
    const [notification, setNotification] = useState();
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalNotifications, setTotalNotifications] = useState([]);
    const [sortBy, setSortBy] = useState(true);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const { currentUser } = useAuth();

    useEffect(() => {
        if(currentUser.tipo.toLowerCase() === "premium"){
            setText(searchParams.get('text') ? searchParams.get('text') : "");
            loadPageData(1, text);
        }
    }, [searchParams]);

    const loadPageData = useCallback (async (page = 1, text) => {
        /*let buildUrl = `/notifications/${currentUser.pk}?limit=8`;
        if(page !== 1 && pagesInfo.length !== 0){
            let entry = pagesInfo.find((p) => {
                return p.page === page;
            });
            buildUrl = buildUrl + (entry.keyStart !== null && entry.keyStart !== undefined ? `&lastKey=${entry.keyStart}`: ``) + (entry.fechaCreacion !== null && entry.fechaCreacion !== undefined ? `&lastDate=${entry.fechaCreacion}`: ``);
        }
        */

        let buildUrl = `/notifications/${currentUser.pk}?limit=30`;
        
        instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
        
        await instance.get(buildUrl).then(function(resp) {
            if(error) setError(false);
            let notis = [];
            if(resp.data?.date?.Items !== undefined){
                notis = resp.data.date.Items.slice(((page - 1)*10), ((page)*10));
                setTotalNotifications(resp.data.date.Items);
                setNotifications(notis);
                setTotalPages(Math.ceil(resp.data.date.Items.length/10));
            }else{
                notis = resp.data.Items.slice(((page - 1)*10), ((page)*10));
                setTotalNotifications(resp.data.Items);
                setNotifications(notis);
                setTotalPages(Math.ceil(resp.data.Items.length/10));
            }
            
            
            /*if(resp.data.pagesInfo !== undefined){
                setPagesInfo(resp.data.pagesInfo);
                setTotalPages(parseInt(resp.data.pagesInfo[resp.data.pagesInfo.length - 1].page));
            }*/
        })
        .catch(function(resp) {
            setError(true);
            Swal.fire({
                icon: "error",
                title: "error",
                text: "Se ha producido un error inesperado, vuelve a intentarlo más tarde.",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Aceptar"
            })
        }).finally(() => {
            setLoading(false);
            if (initialLoading) setInitialLoading(false);
        });
    }, [initialLoading]);

    const dispatcherCall = (page, text) => {
        if(text){
            loadFilterTextNotification(page, text);
        }else{
            loadPageData(page, text);
        }
    }

    const loadFilterTextNotification = async (page=1,text) => {
        let buildUrl = `/notifications/search/${currentUser.pk}?limit=30`;
        instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
        let body = {
            mensaje: text
        };
        
        await instance.post(buildUrl, body).then(function(resp) {
            if(error) setError(false);
            let notis = resp.data.Items.slice(((page - 1)*10), ((page)*10));
            setTotalNotifications(resp.data.Items);
            setNotifications(notis);
            setTotalPages(Math.ceil(resp.data.Items.length/10));
        })
        .catch(function(resp) {
            setError(true);
            Swal.fire({
                icon: "error",
                title: "error",
                text: "Se ha producido un error inesperado, vuelve a intentarlo más tarde.",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Aceptar"
            })
        }).finally(() => {
            setLoading(false);
            if (initialLoading) setInitialLoading(false);
        });
    }

    const readNotification = async (notifications) => {
        let buildUrl = `/notifications/readNotification/`;
        instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}

        let body = [];

        notifications.map((n) => {
            body.push({
                "idUsuario": currentUser.pk,
                "idMensaje": n.idMensaje
            });
        });

        await instance.post(buildUrl, body);
    }

    const handleOpenNotification = (idMensaje) => {
        let notis = notifications.map((n) => {
            if(n.idMensaje === idMensaje){
                readNotification([n]);
                setNotification(n);
                if(!n.leido) n.leido = true;
                if(!open) setOpen(true);
            }
            return n;
        });
        setNotifications(notis);
    }

    const sortReadNotifications = () => {
        setPage(1);
        if(sortBy){
            let totalNotis = totalNotifications.sort((a, b) => a.leido - b.leido);
            let notis = totalNotis.slice(0, 10);
            setNotifications(notis);
            setSortBy(!sortBy);
        }else{
            let totalNotis = totalNotifications.sort((a, b) => b.leido - a.leido);
            let notis = totalNotis.slice(0, 10);
            setNotifications(notis);
            setSortBy(!sortBy);
        }
    }

    const passAllToRead = () => {
        let canSend = false;
        canSend = totalNotifications.some((n) => {
            return n.leido === false;
        });
        if(canSend){
            let totalNotis = totalNotifications.map((n) => {
                n.leido = true;
                return n;
            });
            let notis = notifications.map((n) => {
                n.leido = true;
                return n;
            });
            setNotifications(notis);
            setTotalNotifications(totalNotis);
            readNotification(totalNotifications);
        }
    }

    const handlePageChange = (event, page) => {
        let notis = totalNotifications.slice(((page - 1)*10), ((page)*10));
        setPage(page);
        setNotifications(notis);
        //loadPageData(page, text, category);
    };


    return(
        <>
            {currentUser.tipo.toLowerCase() === "basic" ? <Join text={"Notificaciones"}/> :
                <Box className="cdc-notifications">

                <SearchComponent text={text} setText={setText} search={dispatcherCall} setLoading={setLoading}/>
                {initialLoading ? 
                    (
                        <Box p={3} align="center">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            {loading ? 
                                (
                                    <Box p={3} align="center">
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <>
                                        {!error ?
                                            <>
                                                {notifications.length > 0 ? (
                                                    <>
                                                        <div className="cdc-notifications-options">
                                                            <button className="cdc-notifications-options-sort" type="button" onClick={sortReadNotifications}>
                                                                <figure>
                                                                    <img src={ordenar} alt="Ordenar notificaciones" />
                                                                </figure>
                                                                <p>Ordenar leídos/no leídos</p>
                                                            </button>
                                                            <button className="cdc-notifications-options-sort" type="button" onClick={passAllToRead}>
                                                                <MarkEmailReadIcon style={{fill: '#304D30'}}/>
                                                                <p>Pasar todos a leídos</p> 
                                                            </button>
                                                        </div>
                                                        <ul className="cdc-notifications-list">
                                                            {notifications.map((newElement, index) => {
                                                                return (
                                                                    <>
                                                                        
                                                                        <li className={"cdc-notifications-container" + (newElement.leido ? "": " cdc-notifications-read")} key={"notifications-" + index} onClick={() => handleOpenNotification(newElement.idMensaje)}>
                                                                            {newElement.image &&
                                                                            <figure>
                                                                                <img src={newElement.image} alt={"Imagen " + newElement.titulo}/>
                                                                            </figure>
                                                                            }
                                                                            <Box className="cdc-notifications-container-right">
                                                                                <div>
                                                                                    <p className="cdc-notifications-date">{format(new Date(newElement.fechaCreacion), "dd/MM/yyyy")}</p>
                                                                                    <h2 className="cdc-notifications-title">{newElement.titulo}</h2>
                                                                                    <p className="cdc-notifications-summary">{parse(newElement.mensaje)}</p>
                                                                                </div>
                                                                                {/*<div className="cdc-notifications-extend"></div>*/}
                                                                                <p className="cdc-notifications-date cdc-notifications-hhmm">{format(new Date(newElement.fechaCreacion), "HH:mm")}</p>
                                                                            </Box>
                                                                        </li>
                                                                    </>
                                                                )
                                                            })}
                                                        </ul>
                                                        
                                                        <NotificationDetail open={open} setOpen={setOpen} notification={notification}/>

                                                    </>
                                                ) : (
                                                    <div className="cdc-raffles-notfound">No se han encontrado notificaciones.</div>
                                                )
                                                }
                                            </>
                                            :
                                            <div className="cdc-error-call">Se ha producido un error al intentar obtener la información. Vuelva a intentarlo más tarde.</div>
                                        }
                                    </>
                                )
                            }
                        </>
                    )}
                    <Box sx={{mb: 6, mt: 4}} align="center">
                        <Stack spacing={6} className="cdc-blog-pagination">
                            <Pagination count={totalPages} page={page} onChange={handlePageChange}/>
                        </Stack>
                        {/*<PPagination setLoading={setLoading} loadData={loadPageData} totalPages={totalPages} text={text} category={null}/>*/}
                    </Box>
                </Box>
            }
        </>
    );
}