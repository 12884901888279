import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Grid, TextField, Typography} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { Join } from "../../components/join/Join";
import { useAuth } from "../../firebase/Auth";
import alerta from "../../assets/alerta.svg";
import dcU from "../../assets/docuUser.png";
import { Box } from "@mui/system";
import { instance } from "../../utils/axios-config";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { Link } from "react-router-dom";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { format } from "date-fns";
import { compressImageUpload, validFileSize, validFileType } from '../../services/Commons';

export const Documents = () => {
    
    const [initialLoading, setInitialLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const { currentUser, sessionToken } = useAuth();

    const countInput = useRef();

    const[stateId, setStateId] = useState(currentUser.pk);
    const[stateTipo, setStateTipo] = useState("");
    const[stateNombre, setStateNombre] = useState("");
    const[stateFormato, setStateFormato] = useState("");
    const[stateContent, setStateContent] = useState("");
    const[stateNameFile, setStateNameFile] = useState("");
    const[stateFechaExpedicion, setStateFechaExpedicion] = useState(null);
    const[stateFechaCaducidad, setStateFechaCaducidad] = useState(null);
    /*const [state, setState] = useState({
        idUsuario: currentUser.pk,
        tipo: "",
        formato: "",
        content: "",
        fechaExpedicion: null,
        fechaCaducidad: null,
        nombre: "",
        nameFile: ""
    });*/

    const[stateDniId, setStateDniId] = useState(currentUser.pk);
    const[stateDniTipo, setStateDniTipo] = useState("");
    const[stateDniNombre, setStateDniNombre] = useState("");
    const[stateDniFormatoFront, setStateDniFormatoFront] = useState("");
    const[stateDniContentFront, setStateDniContentFront] = useState("");
    const[stateDniFormatoBack, setStateDniFormatoBack] = useState("");
    const[stateDniContentBack, setStateDniContentBack] = useState("");
    const[stateDniFechaExpedicion, setStateDniFechaExpedicion] = useState(null);
    const[stateDniFechaCaducidad, setStateDniFechaCaducidad] = useState(null);
    /*const [stateDni, setStateDni] = useState({
        idUsuario: currentUser.pk,
        tipo: "",
        formatoFront: "",
        contentFront: "",
        formatoBack: "",
        contentBack: "",
        fechaExpedicion: null,
        fechaCaducidad: null
    });*/

    const [expeditionDate, setExpeditionDate] = useState(null);
    const [expiredDate, setExpiredDate] = useState(null);

    const [autonoma, setAutonoma] = useState();

    const [errors, setErrors] = useState({});
    const [errorsDni, setErrorsDni] = useState({});
    const [pending, setPending] = useState(false);
    const [open, setOpen] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openFail, setOpenFail] = useState(false);
    const [openDniSuccess, setOpenDniSuccess] = useState(false);
    const [openDniFail, setOpenDniFail] = useState(false);
    const [openDni, setOpenDni] = useState(false);
    const [myDni, setMyDni] = useState([]);
    const [myDocs, setMyDocs] = useState([]);
    const [myPhotos, setMyPhotos] = useState([]);
    const [myGunLicences, setMyGunLicences] = useState([]);
    const [myHuntLicences, setMyHuntLicences] = useState([]);
    const [myInsurances, setMyInsurances] = useState([]);
    const [showNewFile, setShowNewFile] = useState(false);
    const [loadingCompress, setLoadingCompress] = useState(false);
    const [loadingCompressSec, setLoadingCompressSec] = useState(false);
    const [errorDocs, setErrorDocs] = useState(false);

    useEffect(() => {
        if(currentUser.tipo.toLowerCase() === "premium"){
            loadMyDocData();
        }
    }, []);

    const loadMyDocData = useCallback( async () => {
        setLoading(true);
        instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
        try{
            await instance.get(`/documentos/documentos/${currentUser.pk}`).then(function(resp) {
                let docs = [];
                let dnis = [];
                let gunLicences = [];
                let huntLicences = [];
                let insurances = [];
                let photos = [];
                resp.data.map((doc) => {
                    doc.fechaSubida = format(new Date(doc.fechaSubida), "dd/MM/yyyy");
                    if(doc.tipoDocumento.toLowerCase().includes("licencia de armas")){
                        gunLicences.push(doc);
                    }else if(doc.tipoDocumento.toLowerCase().includes("licencia de caza")){
                        huntLicences.push(doc);
                    }else if(doc.tipoDocumento.toLowerCase().includes("seguro")){
                        insurances.push(doc);
                    }else if(!doc.tipoDocumento.toLowerCase().includes("dni") && !doc.tipoDocumento.toLowerCase().includes("seguro") && !doc.tipoDocumento.toLowerCase().includes("licencia") && !doc.tipoDocumento.toLowerCase().includes("foto")){
                        docs.push(doc);
                    }else if(doc.tipoDocumento.toLowerCase().includes("dni")){
                        dnis.push(doc);
                    }else if(doc.tipoDocumento.toLowerCase().includes("foto")){
                        photos.push(doc);
                    }
                });
                setMyDocs(docs);
                setMyPhotos(photos);
                setMyDni(dnis);
                setMyGunLicences(gunLicences);
                setMyHuntLicences(huntLicences);
                setMyInsurances(insurances);
            });
        }catch(error){
            setErrorDocs(true);
        }finally{
            setLoading(false);
            if (initialLoading) setInitialLoading(false);
        }
    }, [initialLoading]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors({});
        if(validateForm()){
            sendForm();
        }
    };

    const validateForm = () =>{
        let valid = true;
        let nameMatch = false;
        if(stateTipo === undefined || stateTipo === ''){
            setErrors(errors => ({...errors, errorTipo: true, errorTipoMsg: ""}));
            valid = false;
        }
        if(stateTipo.toLowerCase().includes("licencia de caza")){
            if(autonoma === undefined || autonoma === '' || autonoma === null){
                setErrors(errors => ({...errors, errorAutonoma: true, errorAutonomaMsg: ""}));
                valid = false;
            }
        }
        if(stateNombre === undefined || stateNombre === '' || stateNombre === null){
            setErrors(errors => ({...errors, errorNombre: true, errorNombreMsg: ""}));
            valid = false;
        }else{
            if(stateTipo.toLowerCase().includes("otro")){
                nameMatch = myDocs.some((doc) => {
                    return stateNombre === doc.nombre;
                });
            }else if(stateTipo.toLowerCase().includes("licencia de armas")){
                nameMatch = myGunLicences.some((doc) => {
                    return stateNombre === doc.nombre;
                });
            }else if(stateTipo.toLowerCase().includes("licencia de caza")){
                nameMatch = myHuntLicences.some((doc) => {
                    return stateNombre === doc.nombre;
                });
            }else if(stateTipo.toLowerCase().includes("seguro")){
                nameMatch = myInsurances.some((doc) => {
                    return stateNombre === doc.nombre;
                });
            }else if(stateTipo.toLowerCase().includes("foto")){
                nameMatch = myPhotos.some((doc) => {
                    return stateNombre === doc.nombre;
                });
            }
            if(nameMatch){
                setErrors(errors => ({...errors, errorNombre: true, errorNombreMsg: "El nombre coincide con un documento existente."}));
                valid = false;
            }
        }
        if(stateTipo.toLowerCase() !== "otro" && stateTipo.toLowerCase() !== "foto" && (stateFechaCaducidad === undefined || stateFechaCaducidad === '' || stateFechaCaducidad === null)){
            setErrors(errors => ({...errors, errorFechaCaducidad: true, errorFechaCaducidadMsg: ""}));
            valid = false;
        }
        if(stateTipo.toLowerCase() !== "otro" && stateTipo.toLowerCase() !== "foto" && (stateFechaExpedicion === undefined || stateFechaExpedicion === '' || stateFechaExpedicion === null)){
            setErrors(errors => ({...errors, errorFechaExpedicion: true, errorFechaExpedicionMsg: ""}));
            valid = false;
        }
        if(!stateContent || !stateTipo){
            setErrors(errors => ({...errors, errorContent: true, errorContentMsg: "El archivo no puede estar vacío."}));
            valid = false;
        }
        
        return valid;
    }

    const sendForm = async () => {
  
        setPending(true);

        let body = {
            idUsuario: stateId,
            tipo: stateTipo + (autonoma ? " - " + autonoma : ""),
            formato: stateFormato,
            content: stateContent,
            fechaExpedicion: stateFechaExpedicion,
            fechaCaducidad: stateFechaCaducidad,
            nombre: stateNombre
        }
  
        try {
            await instance.post(`/documentos/documento`, body).then(() => {
                setOpen(false);
                setOpenSuccess(true);
            }).catch(function(resp) {
                setOpenFail(true);
            }).finally(() => {
                setPending(false);
                setOpen(false);
                setExpeditionDate(null);
                setExpiredDate(null);

                setStateId(currentUser.pk);
                setStateTipo("");
                setStateFormato("");
                setStateContent("");
                setStateFechaCaducidad(null);
                setStateFechaExpedicion(null);
                /*setState({
                    idUsuario: currentUser.pk,
                    tipo: "",
                    formato: "",
                    content: "",
                    fechaExpedicion: null,
                    fechaCaducidad: null
                });*/
                loadMyDocData();
            });
        } catch (error) {
            console.warn(error);
            setPending(false);
            setOpenFail(true);
        }
    }


    const handleOpen = () => {
        setOpen(true);
        setErrors({});
        setExpeditionDate(null);
        setExpiredDate(null);
        setStateFechaCaducidad(null);
        setStateFechaExpedicion(null);
        setStateNombre("");
        setStateTipo("");
        setStateFormato("");
        setStateContent("");
        setStateNameFile("");
        //setState({...state, fechaCaducidad: null, fechaExpedicion: null, nombre: "", tipo: "", formato: "", content: "", nameFile: ""});
        setLoadingCompress(false);
    }

    const handleClose = () => {
        //if(!loadingCompress){
            countInput.current.innerText = parseInt(countInput.current.innerText) + 1;
            setOpen(false);
            setErrors({});
            setExpeditionDate(null);
            setExpiredDate(null);
            setStateFechaCaducidad(null);
            setStateFechaExpedicion(null);
            setStateNombre("");
            setStateTipo("");
            setStateFormato("");
            setStateContent("");
            setStateNameFile("");
            //setState({...state, fechaCaducidad: null, fechaExpedicion: null, nombre: "", tipo: "", formato: "", content: "", nameFile: ""});
            setLoadingCompress(false);
        //}
    }

    const handleCloseResponse = () => {
        setOpenFail(false);
        setOpenSuccess(false);
    }

    const handleChangeSelect = (e) => {
        setStateFechaCaducidad((e.target.value.toLowerCase() !== "otro") ? stateFechaCaducidad : null);
        setStateFechaExpedicion((e.target.value !== "" || e.target.value.toLowerCase() !== "otro" ) ? stateFechaExpedicion : null);
        setStateTipo(e.target.value);
        
        //setState(state => ({...state, tipo: e.target.value, fechaExpedicion: (e.target.value !== "" || e.target.value.toLowerCase() !== "otro" ) ? state.fechaExpedicion : null, fechaCaducidad: (e.target.value.toLowerCase() !== "otro") ? state.fechaCaducidad : null}));
        if(e.target.value.toLowerCase() !== "licencia de caza" || e.target.value === ""){
            setAutonoma(null);
        }
        setErrors(errors => ({...errors, errorTipo: false, errorTipoMsg: ""}));
    }

    const handleChangeAutonoma = (e) => {
        setAutonoma(e.target.value);
        setErrors(errors => ({...errors, errorAutonoma: false, errorAutonomaMsg: ""}));
    }

    const handleChangeName = (e) => {
        setStateNombre(e.target.value);
        //setState({...state, nombre: e.target.value});
        setErrors(errors => ({...errors, errorNombre: false, errornombreMsg: ""}));
    }

    const handleChangeDateCaduc = (dayj) => {
        let date = new Date(dayj);
        let today = new Date();
        let dateDate = new Date(date.getFullYear(), date.getMonth(), date.getDay());
        let todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDay());
        let formattedDate = format(date, "dd/MM/yyyy");
        if(dateDate < todayDate){
            setErrors(errors => ({...errors, errorFechaCaducidad: true, errorFechaCaducidadMsg: "La fecha de caducidad no puede ser menor que la fecha actual."}));
        }else{
            setExpiredDate(date);
            setStateFechaCaducidad(formattedDate);
            //setState({...state, fechaCaducidad: formattedDate});
            setErrors(errors => ({...errors, errorFechaCaducidad: false, errorFechaCaducidadMsg: ""}));
        }
      };

    const handleChangeDateExp = (dayj) => {
        let date = new Date(dayj);
        let today = new Date();
        let dateDate = new Date(date.getFullYear(), date.getMonth(), date.getDay());
        let todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDay());
        let formattedDate = format(date, "dd/MM/yyyy");
        if(dateDate > todayDate){
            setErrors(errors => ({...errors, errorFechaExpedicion: true, errorFechaExpedicionMsg: "La fecha de expedición no puede ser mayor que la fecha actual."}));
        }else{
            setExpeditionDate(date);
            setStateFechaExpedicion(formattedDate);
            //setState({...state, fechaExpedicion: formattedDate});
            setErrors(errors => ({...errors, errorFechaExpedicion: false, errorFechaExpedicionMsg: ""}));
        }
    };

    const handleDeleteContent = () => {
        setStateContent("");
        setStateFormato("");
        //setState({...state, content: "", formato: ""});
    };

    const handleChangeFile = async (e) => {
        let previusCount = parseInt(countInput.current.innerText);
        let validType = validFileType(e.target.files[0]);
        let validSize = validFileSize(e.target.files[0]);
        if(!validType){
            setErrors(errors => ({...errors, errorContent: true, errorContentMsg: "El formato del archivo debe ser png, jpg o pdf."}));
        }
        else if(validType && "pdf" === e.target.files[0].type.split("/").pop()){
            if(validSize){
                readFile(e.target.files[0]);
            }else{
                setErrors(errors => ({...errors, errorContent: true, errorContentMsg: "El tamaño del archivo es demasiado grande. Max: 2MB"}));
            }
        }else if(validType && "pdf" !== e.target.files[0].type.split("/").pop()){
            if(validSize){
                readFile(e.target.files[0]);
            }else{
                let result = e.target.files[0];
                try{
                    setLoadingCompress(true);
                    result = await compressImageUpload(e.target.files[0]);
                }catch(error){

                }finally{
                    if(previusCount === parseInt(countInput.current.innerText)){
                        setLoadingCompress(false);
                    }
                }
                if(previusCount === parseInt(countInput.current.innerText)){
                    readFile(result);
                }
                /*if(validFileSize(result)){
                    readFile(result);
                }else{
                    setErrors(errors => ({...errors, errorContent: true, errorContentMsg: "El tamaño del archivo es demasiado grande. Max: 2MB"}));
                }*/
            }
        }
    };

    const readFile = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setStateContent(reader.result.split(",")[1]);
            setStateFormato("." + file.type.split("/").pop());
            setStateNameFile(file.name);
            //setState({...state, content: reader.result.split(",")[1], formato: "." + file.type.split("/").pop(), nameFile: file.name});
            setErrors(errors => ({...errors, errorContent: false, errorContentMsg: ""}));
        };
        reader.onerror = function (error) {
            setErrors(errors => ({...errors, errorContent: true, errorContentMsg: "Vaya, parece que el algo ha salido mal..."}));
        };
    }

    const handleOpenDni = () => {
        setOpenDni(true);
        setExpeditionDate(null);
        setExpiredDate(null);
        setErrorsDni({});
        setShowNewFile(false);
        setLoadingCompress(false);
        setLoadingCompressSec(false);
    };

    const handleCloseDni = () => {
        //if(!loadingCompress && !loadingCompressSec){
            setOpenDni(false);
            setExpeditionDate(null);
            setExpiredDate(null);
            setErrorsDni({});
            countInput.current.innerText = parseInt(countInput.current.innerText) + 1;
            setStateDniTipo("");
            setStateDniNombre("");
            setStateDniFormatoFront("");
            setStateDniContentFront("");
            setStateDniFormatoBack("");
            setStateDniContentBack("");
            setStateDniFechaExpedicion(null);
            setStateDniFechaCaducidad(null);

            //setStateDni({...stateDni, fechaCaducidad: null, fechaExpedicion: null, nombre: "", tipo: "", formatoFront: "", contentFront: "", formatoBack: "", contentBack: ""});
            setShowNewFile(false);
            setLoadingCompress(false);
            setLoadingCompressSec(false);
        //}
    };

    const handleSubmitDni = (e) => {
        e.preventDefault();
        setErrorsDni({});
        if(validateFormDni()){
            sendFormDni();
        }
        
    };

    const handleChangeDniDateCaduc = (dayj) => {
        let date = new Date(dayj);
        let today = new Date();
        let dateDate = new Date(date.getFullYear(), date.getMonth(), date.getDay());
        let todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDay());
        let formattedDate = format(date, "dd/MM/yyyy");
        if(dateDate < todayDate){
            setErrorsDni(errorsDni => ({...errorsDni, errorFechaCaducidad: true, errorFechaCaducidadMsg: "La fecha de caducidad no puede ser menor que la fecha actual."}));
        }else{
            setExpiredDate(date);
            setStateDniFechaCaducidad(formattedDate);
            //setStateDni({...stateDni, fechaCaducidad: formattedDate});
            setErrorsDni(errorsDni => ({...errorsDni, errorFechaCaducidad: false, errorFechaCaducidadMsg: ""}));
        }
      };

    const handleChangeDniDateExp = (dayj) => {
        let date = new Date(dayj);
        let today = new Date();
        let dateDate = new Date(date.getFullYear(), date.getMonth(), date.getDay());
        let todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDay());
        let formattedDate = format(date, "dd/MM/yyyy");
        if(dateDate > todayDate){
            setErrorsDni(errorsDni => ({...errorsDni, errorFechaExpedicion: true, errorFechaExpedicionMsg: "La fecha de expedición no puede ser mayor que la fecha actual."}));
        }else{
            setExpeditionDate(date);
            setStateDniFechaExpedicion(formattedDate);
            //setStateDni({...stateDni, fechaExpedicion: formattedDate});
            setErrorsDni(errorsDni => ({...errorsDni, errorFechaExpedicion: false, errorFechaExpedicionMsg: ""}));
        }
    };

    const handleDeleteContentFront = () => {
        setStateDniContentFront("");
        setStateDniFormatoFront("");
        //setStateDni({...stateDni, contentFront: "", formatoFront: ""});
    };

    const handleDeleteContentBack = () => {
        setStateDniContentBack("");
        setStateDniFormatoBack("");
        //setStateDni({...stateDni, contentBack: "", formatoBack: ""});
    };

    const handleCloseDniResponse = () => {
        setOpenDniFail(false);
        setOpenDniSuccess(false);
    }

    const handleChangeFrontFile = async (e) => {
        let previusCount = parseInt(countInput.current.innerText);
        let validType = validFileType(e.target.files[0]);
        let validSize = validFileSize(e.target.files[0]);
        if(!validType){
            setErrorsDni(errorsDni => ({...errorsDni, errorContentFront: true, errorContentFrontMsg: "El formato del archivo debe ser png, jpg o pdf."}));
        }
        else if(validType && "pdf" === e.target.files[0].type.split("/").pop()){
            if(validSize){
                readFileFront(e.target.files[0]);
            }else{
                setErrorsDni(errorsDni => ({...errorsDni, errorContentFront: true, errorContentFrontMsg: "El tamaño del archivo es demasiado grande. Max: 2MB"}));
            }
        }else if(validType && "pdf" !== e.target.files[0].type.split("/").pop()){
            if(validSize){
                readFileFront(e.target.files[0]);
            }else{
                let result = e.target.files[0];
                try{
                    setLoadingCompress(true);
                    result = await compressImageUpload(e.target.files[0]);
                }catch(error){

                }finally{
                    if(previusCount === parseInt(countInput.current.innerText)){
                        setLoadingCompress(false);
                    }
                }
                if(previusCount === parseInt(countInput.current.innerText)){
                    readFileFront(result);
                }
                /*if(validFileSize(result)){
                    readFileFront(result);
                }else{
                    setErrorsDni(errorsDni => ({...errorsDni, errorContentFront: true, errorContentFrontMsg: "El tamaño del archivo es demasiado grande. Max: 2MB"}));
                }*/
            }
        }
    };

    const readFileFront = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setStateDniContentFront(reader.result.split(",")[1]);
            setStateDniFormatoFront("." + file.type.split("/").pop());
            //setStateDni({...stateDni, contentFront: reader.result.split(",")[1], formatoFront: "." + file.type.split("/").pop()});
            setErrorsDni(errorsDni => ({...errorsDni, errorContentFront: false, errorContentFrontMsg: ""}));
        };
        reader.onerror = function (error) {
            setErrorsDni(errorsDni => ({...errorsDni, errorContentFront: true, errorContentFrontMsg: "Vaya, parece que el algo ha salido mal..."}));
        };
    }

    const handleChangeBackFile = async(e) => {
        let previusCount = parseInt(countInput.current.innerText);
        let validType = validFileType(e.target.files[0]);
        let validSize = validFileSize(e.target.files[0]);
        if(!validType){
            setErrorsDni(errorsDni => ({...errorsDni, errorContentBack: true, errorContentBackMsg: "El formato del archivo debe ser png, jpg o pdf."}));
        }
        else if(validType && "pdf" === e.target.files[0].type.split("/").pop()){
            if(validSize){
                readFileBack(e.target.files[0]);
            }else{
                setErrorsDni(errorsDni => ({...errorsDni, errorContentBack: true, errorContentBackMsg: "El tamaño del archivo es demasiado grande. Max: 2MB"}));
            }
        }else if(validType && "pdf" !== e.target.files[0].type.split("/").pop()){
            if(validSize){
                readFileBack(e.target.files[0]);
            }else{
                let result = e.target.files[0];
                try{
                    setLoadingCompressSec(true);
                    result = await compressImageUpload(e.target.files[0]);
                }catch(error){

                }finally{
                    if(previusCount === parseInt(countInput.current.innerText)){
                        setLoadingCompressSec(false);
                    }
                }
                if(previusCount === parseInt(countInput.current.innerText)){
                    readFileBack(result);
                }
                /*if(validFileSize(result)){
                    readFileBack(result);
                }else{
                    setErrorsDni(errorsDni => ({...errorsDni, errorContentBack: true, errorContentBackMsg: "El tamaño del archivo es demasiado grande. Max: 2MB"}));
                }*/
            }
        }
    };

    const readFileBack = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setStateDniContentBack(reader.result.split(",")[1]);
            setStateDniFormatoBack("." + file.type.split("/").pop());
            //setStateDni({...stateDni, contentBack: reader.result.split(",")[1], formatoBack: "." + file.type.split("/").pop()});
            setErrorsDni(errorsDni => ({...errorsDni, errorContentBack: false, errorContentBackMsg: ""}));
        };
        reader.onerror = function (error) {
            setErrorsDni(errorsDni => ({...errorsDni, errorContentBack: true, errorContentBackMsg: "Vaya, parece que el algo ha salido mal..."}));
        };
    }

    const changeAddNewFile = () => {
        setShowNewFile(true);
    }

    const validateFormDni = () =>{
        let valid = true;
        let nameMatch = false;
        if(stateDniFechaCaducidad === undefined || stateDniFechaCaducidad === '' || stateDniFechaCaducidad === null){
            setErrorsDni(errorsDni => ({...errorsDni, errorFechaCaducidad: true, errorFechaCaducidadMsg: ""}));
            valid = false;
        }
        if(stateDniFechaExpedicion === undefined || stateDniFechaExpedicion === '' || stateDniFechaExpedicion === null){
            setErrorsDni(errorsDni => ({...errorsDni, errorFechaExpedicion: true, errorFechaExpedicionMsg: ""}));
            valid = false;
        }
        if(!stateDniContentFront){
            setErrorsDni(errorsDni => ({...errorsDni, errorContentFront: true, errorContentFrontMsg: "El archivo no puede estar vacío."}));
            valid = false;
        }
        if(stateDniContentFront || stateDniContentBack){
            nameMatch = myDni.some((dni) => {
                return ("Documento DNI" === dni.nombre || "Documento 2º DNI" === dni.nombre);
            });
        }

        if(nameMatch){
            setErrorsDni(errorsDni => ({...errorsDni, errorNombre: true, errorNombreMsg: "Ya tienes un DNI de este tipo enviado."}));
            valid = false;
        }
        
        return valid;
    }

    const sendFormDni = async () => {
  
        setPending(true);

        let bodyfront = {
            idUsuario: stateDniId,
            tipo: "DNI",
            nombre: "Documento DNI",
            formato: stateDniFormatoFront,
            content: stateDniContentFront,
            fechaExpedicion: stateDniFechaExpedicion,
            fechaCaducidad: stateDniFechaCaducidad
        }
        let bodyback = {
            idUsuario: stateDniId,
            tipo: "DNI",
            nombre: "Documento 2º DNI",
            formato: stateDniFormatoBack,
            content: stateDniContentBack,
            fechaExpedicion: stateDniFechaExpedicion,
            fechaCaducidad: stateDniFechaCaducidad
        }
  
        try {
            let dniFiles = [];
            const dniFront = instance.post(`/documentos/documento`, bodyfront);
            dniFiles.push(dniFront);
            if(bodyback.content){
                const dniBack = instance.post(`/documentos/documento`, bodyback);
                dniFiles.push(dniBack);
            }
            Promise.all(dniFiles).then(function(values) {
                setOpenDni(false);
                setOpenDniSuccess(true);
            }).catch(function(resp) {
                setOpenDniFail(true);
            }).finally(() => {
                setPending(false);
                setOpenDni(false);
                setExpeditionDate(null);
                setExpiredDate(null);
                setShowNewFile(false);

                setStateDniTipo("");
                setStateDniNombre("");
                setStateDniFormatoFront("");
                setStateDniContentFront("");
                setStateDniFormatoBack("");
                setStateDniContentBack("");
                setStateDniFechaExpedicion(null);
                setStateDniFechaCaducidad(null);
                /*setStateDni({
                    idUsuario: currentUser.pk,
                    tipo: "",
                    formatoFront: "",
                    contentFront: "",
                    formatoBack: "",
                    contentBack: "",
                    fechaExpedicion: null,
                    fechaCaducidad: null
                });*/
                loadMyDocData();
            });
        } catch (error) {
            console.warn(error);
            setPending(false);
            setOpenDniFail(true);
        }
    }

    const statusPrint = (status) =>{
        if(status === "Revision"){
            return( <span style={{color:"#DCC500"}}>EN REVISIÓN</span>);
        }else if(status === "Rechazado"){
            return( <span style={{color:"red"}}>RECHAZADO</span>);
        }else{
            return( <span style={{color:"#00A632"}}>APROBADO</span>);
        }
    }

    const checkDate = (date) => {
        let result = "";
        let today = new Date();
        let todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDay());
        let arrayD = date.split("/");
        let dateDate = new Date(arrayD[2], arrayD[1] - 1, arrayD[0]);
        if(dateDate < todayDate){
            result = "cdc-documents-expire";
        }
        return result;
    }

    return (
        <>
            {currentUser.tipo.toLowerCase() === "basic" ? <Join text={"Documentación"}/> : 
            
            <Grid className='cdc-documents'>
                <Grid className="cdc-documents-card">
                    <img src={dcU} alt={"Tarjeta Usuario"}/>
                    <Typography className="cdc-documents-card-dni">{currentUser.dni}</Typography>
                    <Typography className="cdc-documents-card-nombre">{currentUser.nombre}</Typography>
                    <Typography className="cdc-documents-card-apellidos">{currentUser.apellido}</Typography>
                    <Typography className="cdc-documents-card-validez">Válido hasta: {currentUser?.fechaRenovacion ? format(new Date(currentUser.fechaRenovacion), "dd/MM/yyyy") : ""}</Typography>
                    <Typography className="cdc-documents-card-nSocio">{currentUser.nroSocio}</Typography>
                </Grid>
                <Grid className="cdc-documents-dnit">
                    <Typography variant="h1">
                        DNI del titular
                    </Typography>
                    <Typography>
                        En esta sección podrás subir y encontrar tu DNI, ten en cuenta que cada vez que actualices tu DNI pasará a un estado de revisión para comprobar que todo esté correcto.
                    </Typography>
                </Grid>

                {initialLoading ? 
                    (
                        <Box p={3} align="center">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            {loading ? 
                                (
                                    <Box p={3} align="center">
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <>
                                        {!errorDocs ?
                                            <Grid className="cdc-documents-licencias">
                                                
                                                    {myDni.length > 0 ? (
                                                        <ul className="cdc-documents-list">
                                                            {myDni.map((newElement, index) => {
                                                                return (
                                                                    <>
                                                                    <Link className="cdc-documents-list-link" to={"/documento/" + encodeURIComponent(newElement.idDocumento)} key={"document-dni" + index}> 
                                                                            <li className="cdc-documents-container">
                                                                                <Box className="cdc-documents-container-right">
                                                                                    <div>
                                                                                        <p className="cdc-documents-date">Fecha de expedición: {newElement.fechaExpedicion}</p>
                                                                                        <h2 className="cdc-documents-title">{newElement.nombre}</h2>
                                                                                        <p className="cdc-documents-summary">Caducidad: {newElement.fechaCaducidad}</p>
                                                                                    </div>
                                                                                    <div className="cdc-documents-container-status">{statusPrint(newElement.estado)}</div>
                                                                                </Box>
                                                                            </li>
                                                                        </Link>
                                                                    </>
                                                                )
                                                            })}
                                                        </ul>
                                                    ) : (
                                                        <div className="cdc-documents-notfound">No se han encontrado documentos relacionados al DNI.</div>
                                                    )
                                                    }
                                                                            
                                            </Grid>
                                            :
                                            <div className="cdc-error-call">Se ha producido un error al intentar obtener la información. Vuelva a intentarlo más tarde.</div>
                                        }
                                    </>
                                )
                            }
                        </>
                    )
                }

                <Grid className="cdc-documents-dni">
                    <figure className="cdc-documents-dni-preview">
                        <img src={alerta} alt={"Alerta Archivo"}/>
                    </figure>
                    <Typography>Subir archivos en pdf/jpg/png</Typography>
                </Grid>
                <Box noValidate>
                    <button className="cdc-documents-button" onClick={handleOpenDni}>Subir DNI</button>
                </Box>

                <button className="cdc-documents-upload-button" onClick={handleOpen}>
                    <DriveFolderUploadIcon style={{ fill: '#304D30' }}/>
                    <p className="cdc-documents-upload-label">
                        Subir nuevo documento
                    </p>
                </button>
                {initialLoading ? 
                        (
                            <Box p={3} align="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                {loading ? 
                                    (
                                        <Box p={3} align="center">
                                            <CircularProgress />
                                        </Box>
                                    ) : (
                                        <>
                                            {!errorDocs ?
                                                <>
                                                    <Grid className="cdc-documents-licencias">
                                                        <Typography variant="h1" className="cdc-documents-color-insurances">Seguros</Typography>
                                                        <Typography>
                                                        En sección puedes subir tus seguros para tenerlos siempre contigo,
                                                         los revisaremos y te avisaremos cuando llegue la fecha de renovarlo.
                                                        </Typography>
                                                        
                                                            {myInsurances.length > 0 ? (
                                                                <ul className="cdc-documents-list">
                                                                    {myInsurances.map((newElement, index) => {
                                                                        return (
                                                                            <>
                                                                            <Link className="cdc-documents-list-link" to={"/documento/" + encodeURIComponent(newElement.idDocumento)} key={"document-insurance" + index}> 
                                                                                    <li className="cdc-documents-container">
                                                                                        <Box className="cdc-documents-container-right">
                                                                                            <div>
                                                                                                <p className="cdc-documents-date">Fecha de expedición: {newElement.fechaExpedicion}</p>
                                                                                                <h2 className="cdc-documents-title">Nombre: {newElement.nombre}</h2>
                                                                                                <p className="cdc-documents-summary">Caducidad: {newElement.fechaCaducidad}</p>
                                                                                            </div>
                                                                                            <div className="cdc-documents-container-status">{statusPrint(newElement.estado)}</div>
                                                                                        </Box>
                                                                                    </li>
                                                                                </Link>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            ) : (
                                                                <div className="cdc-documents-notfound">No se han encontrado documentos.</div>
                                                            )
                                                            }
                                                                                    
                                                    </Grid>
                                                    <hr className="cdc-documents-section"></hr>
                                                    <Grid className="cdc-documents-licencias">
                                                        <Typography variant="h1" className="cdc-documents-color-guns">Licencias de armas</Typography>
                                                        <Typography>
                                                        En sección puedes subir tu permiso de armas para tenerlo siempre contigo,
                                                        lo revisaremos y te avisaremos cuando llegue la fecha de renovarlo.
                                                        Recuerda que por ley es necesario llevarlo también en formato físico en caso de que
                                                        te lo requiera un agente de la autoridad.
                                                        </Typography>
                                                        
                                                            {myGunLicences.length > 0 ? (
                                                                <ul className="cdc-documents-list">
                                                                    {myGunLicences.map((newElement, index) => {
                                                                        return (
                                                                            <>
                                                                            <Link className="cdc-documents-list-link" to={"/documento/" + encodeURIComponent(newElement.idDocumento)} key={"document-license-gun" + index}> 
                                                                                    <li className="cdc-documents-container">
                                                                                        <Box className="cdc-documents-container-right">
                                                                                            <div>
                                                                                                <p className="cdc-documents-date">Fecha de expedición: {newElement.fechaExpedicion}</p>
                                                                                                <h2 className="cdc-documents-title">Nombre: {newElement.nombre}</h2>
                                                                                                <p className={"cdc-documents-summary " + checkDate(newElement.fechaCaducidad)}>Caducidad: {newElement.fechaCaducidad}</p>
                                                                                            </div>
                                                                                            <div className="cdc-documents-container-status">{statusPrint(newElement.estado)}</div>
                                                                                        </Box>
                                                                                    </li>
                                                                                </Link>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            ) : (
                                                                <div className="cdc-documents-notfound">No se han encontrado documentos.</div>
                                                            )
                                                            }
                                                                                    
                                                    </Grid>
                                                    <hr className="cdc-documents-section"></hr>
                                                    <Grid className="cdc-documents-licencias">
                                                        <Typography variant="h1" className="cdc-documents-color-hunts">Licencias de caza</Typography>
                                                        <Typography>
                                                        En sección puedes subir tus licencias de caza para tenerlas siempre contigo,
                                                        las revisaremos y te avisaremos cuando llegue la fecha de renovación.
                                                        Recuerda que en algunas CCAA puede ser necesario llevarlas físicamente.
                                                        </Typography>
                                                        
                                                            {myHuntLicences.length > 0 ? (
                                                                <ul className="cdc-documents-list">
                                                                    {myHuntLicences.map((newElement, index) => {
                                                                        return (
                                                                            <>
                                                                            <Link className="cdc-documents-list-link" to={"/documento/" + encodeURIComponent(newElement.idDocumento)} key={"document-license-hunt" + index}> 
                                                                                    <li className="cdc-documents-container">
                                                                                        <Box className="cdc-documents-container-right">
                                                                                            <div>
                                                                                                <p className="cdc-documents-date">Fecha de expedición: {newElement.fechaExpedicion}</p>
                                                                                                <h2 className="cdc-documents-title">Nombre: {newElement.nombre}</h2>
                                                                                                <p className="cdc-documents-summary">Caducidad: {newElement.fechaCaducidad}</p>
                                                                                            </div>
                                                                                            <div className="cdc-documents-container-status">{statusPrint(newElement.estado)}</div>
                                                                                        </Box>
                                                                                    </li>
                                                                                </Link>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            ) : (
                                                                <div className="cdc-documents-notfound">No se han encontrado documentos.</div>
                                                            )
                                                            }
                                                                                    
                                                    </Grid>
                                                    <hr className="cdc-documents-section"></hr>
                                                    <Grid className="cdc-documents-licencias">
                                                        <Typography variant="h1" className="cdc-documents-color-docs">Mis documentos</Typography>
                                                        <Typography>
                                                        En sección puedes subir cualquier documento que puedas necesitar
                                                        durante el desarrollo de la caza como, autorizaciones de cotos,
                                                        permiso en una reserva, declaraciones juradas, etc.
                                                        </Typography>
                                                        
                                                            {myDocs.length > 0 ? (
                                                                <ul className="cdc-documents-list">
                                                                    {myDocs.map((newElement, index) => {
                                                                        return (
                                                                            <>
                                                                            <Link className="cdc-documents-list-link" to={"/documento/" + encodeURIComponent(newElement.idDocumento)} key={"document-" + index}> 
                                                                                    <li className="cdc-documents-container">
                                                                                        <Box className="cdc-documents-container-right">
                                                                                            <div className="cdc-documents-container-right-center">
                                                                                                <p className="cdc-documents-date">Fecha de creación: {newElement.fechaSubida}</p>
                                                                                                <h2 className="cdc-documents-title">Nombre: {newElement.nombre}</h2>
                                                                                            </div>
                                                                                            <div className="cdc-documents-container-status">{statusPrint(newElement.estado)}</div>
                                                                                        </Box>
                                                                                    </li>
                                                                                </Link>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            ) : (
                                                                <div className="cdc-documents-notfound">No se han encontrado documentos.</div>
                                                            )
                                                            }
                                                    </Grid>
                                                    <hr className="cdc-documents-section"></hr>
                                                    <Grid className="cdc-documents-licencias">
                                                        <Typography variant="h1" className="cdc-documents-color-docs">Mis fotos</Typography>
                                                        <Typography>
                                                        Lleva tu álbum de fotos de caza siempre contigo,
                                                        de una forma ordenada y accesible en cualquier momento,
                                                        para que disfrutes de cada lance una y otra vez.
                                                        </Typography>
                                                        
                                                            {myPhotos.length > 0 ? (
                                                                <ul className="cdc-documents-list">
                                                                    {myPhotos.map((newElement, index) => {
                                                                        return (
                                                                            <>
                                                                            <Link className="cdc-documents-list-link" to={"/documento/" + encodeURIComponent(newElement.idDocumento)} key={"document-" + index}> 
                                                                                    <li className="cdc-documents-container">
                                                                                        <Box className="cdc-documents-container-right">
                                                                                            <div className="cdc-documents-container-right-center">
                                                                                                <p className="cdc-documents-date">Fecha de creación: {newElement.fechaSubida}</p>
                                                                                                <h2 className="cdc-documents-title">Nombre: {newElement.nombre}</h2>
                                                                                                {/*<p className="cdc-documents-summary">Caducidad: {newElement.fechaCaducidad}</p>*/}
                                                                                            </div>
                                                                                            <div className="cdc-documents-container-status">{statusPrint(newElement.estado)}</div>
                                                                                        </Box>
                                                                                    </li>
                                                                                </Link>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            ) : (
                                                                <div className="cdc-documents-notfound">No se han encontrado fotos.</div>
                                                            )
                                                            }
                                                    </Grid>
                                                </>
                                                :
                                                <div className="cdc-error-call">Se ha producido un error al intentar obtener la información. Vuelva a intentarlo más tarde.</div>
                                            }
                                        </>
                                    )
                                }
                            </>
                        )
                }

                <Dialog open={open} onClose={handleClose} className="cdc-documents-modal-success">
                    <DialogContent className="cdc-documents-modal">
                        <Box component="form" onSubmit={(e) => handleSubmit(e)}>
                        <DialogContentText style={{marginBottom: "20px"}}>Para subir un nuevo documento, por favor, cumplimenta el siguiente formulario en función del tipo de documento que deseas agregar.</DialogContentText>
                            <div className="cdc-documents-upload-type">
                                <label htmlFor="cdc-documents-upload-type-label">TIPO DE DOCUMENTO*</label>
                                <select name="cdc-documents-upload-type" id="cdc-documents-upload-type" value={stateTipo} onChange={(e) => handleChangeSelect(e)} className={errors.errorTipo ? "cdc-red-flag": ""}>
                                    <option value="">{"-"}</option>
                                    <option value="Licencia de armas">Licencia de armas</option>
                                    <option value="Licencia de caza">Licencia de caza</option>
                                    <option value="Seguro">Seguro</option>
                                    <option value="Otro">Otro</option>
                                    <option value="Foto">Foto</option>
                                </select>
                                {errors.errorTipo && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorTipoMsg}</span>}
                            </div>
                            {stateTipo.toLowerCase() === "licencia de caza" &&
                                <div className="cdc-documents-upload-type">
                                    <label htmlFor="cdc-documents-upload-type-label">COMN. AUTÓNOMA*</label>
                                    <select name="cdc-documents-upload-type" id="cdc-documents-upload-aut" value={autonoma} onChange={(e) => handleChangeAutonoma(e)} className={errors.errorAutonoma ? "cdc-red-flag": ""}>
                                        <option value="">{"-"}</option>
                                        <option value="Andalucía">Andalucía</option>
                                        <option value="Aragón">Aragón</option>
                                        <option value="Asturias, Principado de">Asturias, Principado de</option>
                                        <option value="Balears, Illes">Balears, Illes</option>
                                        <option value="Canarias">Canarias</option>
                                        <option value="Cantabria">Cantabria</option>
                                        <option value="Castilla y León">Castilla y León</option>
                                        <option value="Castilla - La Mancha">Castilla - La Mancha</option>
                                        <option value="Cataluña">Cataluña</option>
                                        <option value="Comunitat Valenciana">Comunitat Valenciana</option>
                                        <option value="Extremadura">Extremadura</option>
                                        <option value="Galicia">Galicia</option>
                                        <option value="Madrid, Comunidad de">Madrid, Comunidad de</option>
                                        <option value="Murcia, Región de">Murcia, Región de</option>
                                        <option value="Navarra, Comunidad Foral de">Navarra, Comunidad Foral de</option>
                                        <option value="País Vasco">País Vasco</option>
                                        <option value="Rioja, La">Rioja, La</option>
                                        <option value="Interautonómica">Interautonómica</option>
                                    </select>
                                    {errors.errorAutonoma && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorAutonomaMsg}</span>}
                                </div>
                            }
                            <div className="cdc-documents-upload-type">
                                <label htmlFor="cdc-documents-upload-name">NOMBRE / CONCEPTO*</label>
                                <input type="text" id="cdc-documents-upload-name" name="cdc-documents-upload-name" placeholder="Nombre del documento" value={stateNombre} onChange={(e) => handleChangeName(e)} className={errors.errorNombre ? "cdc-red-flag": ""}/>
                                {errors.errorNombre && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorNombreMsg}</span>}
                            </div>
                            {stateTipo.toLowerCase() !== "otro" && stateTipo.toLowerCase() !== "foto" && stateTipo.toLowerCase() !== "" &&
                                <>
                                <div className="cdc-documents-upload-caduc">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker
                                            label="FECHA DE CADUCIDAD*"
                                            value={expiredDate}
                                            inputFormat="DD/MM/YYYY"
                                            onChange={handleChangeDateCaduc}
                                            renderInput={(params) => <TextField {...params} id="fechaCaducidadFile" className={errors.errorFechaCaducidad ? "cdc-red-flag-picker": ""} sx={{
                                                '.MuiInputBase-input': {padding: 0},
                                                '.MuiInputBase-root fieldset': {border: "0 !important"},
                                                '.MuiInputBase-root': {
                                                    width: "100%",
                                                    fontFamily: "Roboto",
                                                    padding: "10px 10px 10px 0",
                                                    borderBottom: "1px solid #f2f2f2",
                                                    outline: "none",
                                                },
                                                '.MuiFormLabel-root': {
                                                    margin: "0 !important",
                                                    position: "relative",
                                                    textAlign: "left",
                                                    maxWidth: "100%",
                                                    transform: "none",
                                                    fontFamily: "Roboto",
                                                    fontWeight: "500",
                                                    fontSize: "10px",
                                                    lineHeight: "11px",
                                                    color: "#828282",
                                                },
                                                '.MuiFormLabel-root.Mui-focused':{
                                                    color: "#828282",
                                                },
                                                '.MuiInputBase-root.Mui-focused fieldset': {
                                                    border: "none",
                                                    borderBottom: "1px solid #000",
                                                    borderRadius: "0",
                                                    borderTop: "0",
                                                    borderRight: "0",
                                                    borderLeft: "0"
                                                }
                                            }}/>}
                                        />
                                    </LocalizationProvider>
                                    {errors.errorFechaCaducidad && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorFechaCaducidadMsg}</span>}
                                </div>
                                
                                <div className="cdc-documents-upload-exp">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker
                                            label="FECHA DE EXPEDICIÓN*"
                                            value={expeditionDate}
                                            inputFormat="DD/MM/YYYY"
                                            onChange={handleChangeDateExp}
                                            renderInput={(params) => <TextField {...params} id="fechaExpedicionFile" className={errors.errorFechaExpedicion ? "cdc-red-flag-picker": ""} sx={{
                                                '.MuiInputBase-input': {padding: 0},
                                                '.MuiInputBase-root fieldset': {border: "0 !important"},
                                                '.MuiInputBase-root': {
                                                    width: "100%",
                                                    fontFamily: "Roboto",
                                                    padding: "10px 10px 10px 0",
                                                    borderBottom: "1px solid #f2f2f2",
                                                    outline: "none",
                                                },
                                                '.MuiFormLabel-root': {
                                                    margin: "0 !important",
                                                    position: "relative",
                                                    textAlign: "left",
                                                    maxWidth: "100%",
                                                    transform: "none",
                                                    fontFamily: "Roboto",
                                                    fontWeight: "500",
                                                    fontSize: "10px",
                                                    lineHeight: "11px",
                                                    color: "#828282",
                                                },
                                                '.MuiFormLabel-root.Mui-focused':{
                                                    color: "#828282",
                                                },
                                                '.MuiInputBase-root.Mui-focused fieldset': {
                                                    border: "none",
                                                    borderBottom: "1px solid #000",
                                                    borderRadius: "0",
                                                    borderTop: "0",
                                                    borderRight: "0",
                                                    borderLeft: "0"
                                                }
                                            }}/>}
                                        />
                                    </LocalizationProvider>
                                    {errors.errorFechaExpedicion && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorFechaExpedicionMsg}</span>}
                                </div>
                                </>
                            }
                            <div className="cdc-documents-file-container">
                                {stateContent ?
                                    <>
                                        {/*<div>
                                            <FilePresentIcon style={{ fill: '#304D30', width: '50px', height: '50px' }}/>
                                            <p>Archivo adjuntado</p>
                                        </div>
                                        <button type="button" onClick={handleDeleteContent}><DeleteOutlineIcon style={{ fill: '#304D30'}}/></button>*/}
                                        <div className="flex-container align-center" style={{backgroundColor: "rgba(0, 0, 0, 0.04)", justifyContent: "space-between", padding: "0 5px"}}>
                                            <div className="flex-container align-center">
                                                <FilePresentIcon style={{ fill: '#304D30', width: '30px', height: '30px' }}/>
                                                {stateNameFile ? <p>{stateNameFile}</p> : <p style={{color: "red"}}>Sin nombre</p>}
                                            </div>
                                            <button className="cdc-documents-file-delete" type="button" style={{marginLeft: "5px"}} onClick={handleDeleteContent}><DeleteOutlineIcon style={{ fill: '#304D30'}}/></button>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {loadingCompress ?
                                            <Box p={3} align="center">
                                                <CircularProgress />
                                                <p>Comprimiendo imagen...</p>
                                            </Box>
                                        : 
                                        <>
                                            <button type="button" className="cdc-documents-subir" >
                                                <input className="cdc-documents-subir-inp" id="cdc-file-new" type="file" name="cdc-file-new" onChange={handleChangeFile}/>
                                                <label htmlFor="cdc-file-new" className="cdc-documents-subir-label">
                                                    <DriveFolderUploadIcon style={{ fill: '#304D30' }} alt={"Subir nuevo documento"}/>
                                                    Subir nuevo documento
                                                </label>
                                            </button>
                                            {errors.errorContent && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorContentMsg}</span>}
                                        
                                        </>
                                        }
                                        
                                    </>
                                }
                                </div>
                            <DialogActions className="cdc-documents-modal-close">
                                { pending ?
                                    <>
                                        <p style={{fontSize: "18px", color: "#304D30", fontWeight: "600"}}>Enviando...</p>
                                    </>
                                    :
                                    <>
                                        <button type="button" onClick={handleClose} variant="contained">Cerrar</button>
                                        <button type="submit" disabled={loadingCompress}>Guardar</button>
                                    </>
                                }
                                
                            </DialogActions>
                        </Box>
                    </DialogContent>
                </Dialog>
                <Dialog open={openSuccess} onClose={handleCloseResponse} className="cdc-documents-modal-success">
                    <DialogContent className="cdc-documents-modal">
                        <Box>
                            <DialogContentText style={{marginBottom: "20px"}}>¡Se ha enviado el documento!</DialogContentText>
                            
                            <DialogActions className="cdc-documents-modal-close"> 
                                <button type="button" onClick={handleCloseResponse} variant="contained">Cerrar</button>
                            </DialogActions>
                        </Box>
                    </DialogContent>
                </Dialog>
                <Dialog open={openFail} onClose={handleCloseResponse} className="cdc-documents-modal-fail">
                    <DialogContent className="cdc-documents-modal">
                        <Box>
                            <DialogContentText style={{marginBottom: "20px"}}>¡Vaya, parece que algo ha fallado!</DialogContentText>
                            <DialogContentText style={{marginBottom: "20px"}}>Por favor, vuelve a intentarlo más tarde.</DialogContentText>
                            
                            <DialogActions className="cdc-documents-modal-close"> 
                                <button type="button" onClick={handleCloseResponse} variant="contained">Cerrar</button>
                            </DialogActions>
                        </Box>
                    </DialogContent>
                </Dialog>


                <Dialog open={openDni} onClose={handleCloseDni} className="cdc-documents-modal-dni">
                    <DialogContent className="cdc-documents-modal">
                        <Box component="form" onSubmit={(e) => handleSubmitDni(e)}>
                            <DialogContentText style={{marginBottom: "20px"}}>Para subir su DNI, por favor, cumplimenta el siguiente formulario con todos los datos referentes a su DNI.</DialogContentText>
                            <div className="cdc-documents-upload-caduc">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        label="FECHA DE CADUCIDAD*"
                                        value={expiredDate}
                                        inputFormat="DD/MM/YYYY"
                                        onChange={handleChangeDniDateCaduc}
                                        renderInput={(params) => <TextField {...params} id="fechaCaducidadDniFile" className={errorsDni.errorFechaCaducidad ? "cdc-red-flag-picker": ""} sx={{
                                            '.MuiInputBase-input': {padding: 0},
                                            '.MuiInputBase-root fieldset': {border: "0 !important"},
                                            '.MuiInputBase-root': {
                                                width: "100%",
                                                fontFamily: "Roboto",
                                                padding: "10px 10px 10px 0",
                                                borderBottom: "1px solid #f2f2f2",
                                                outline: "none",
                                            },
                                            '.MuiFormLabel-root': {
                                                margin: "0 !important",
                                                position: "relative",
                                                textAlign: "left",
                                                maxWidth: "100%",
                                                transform: "none",
                                                fontFamily: "Roboto",
                                                fontWeight: "500",
                                                fontSize: "10px",
                                                lineHeight: "11px",
                                                color: "#828282",
                                            },
                                            '.MuiFormLabel-root.Mui-focused':{
                                                color: "#828282",
                                            },
                                            '.MuiInputBase-root.Mui-focused fieldset': {
                                                border: "none",
                                                borderBottom: "1px solid #000",
                                                borderRadius: "0",
                                                borderTop: "0",
                                                borderRight: "0",
                                                borderLeft: "0"
                                            }
                                         }}/>}
                                    />
                                </LocalizationProvider>
                                {errorsDni.errorFechaCaducidad && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errorsDni.errorFechaCaducidadMsg}</span>}
                            </div>
                            <div className="cdc-documents-upload-exp">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        label="FECHA DE EXPEDICIÓN*"
                                        value={expeditionDate}
                                        inputFormat="DD/MM/YYYY"
                                        onChange={handleChangeDniDateExp}
                                        renderInput={(params) => <TextField {...params} id="fechaExpedicionDniFile" className={errorsDni.errorFechaExpedicion ? "cdc-red-flag-picker": ""} sx={{
                                            '.MuiInputBase-input': {padding: 0},
                                            '.MuiInputBase-root fieldset': {border: "0 !important"},
                                            '.MuiInputBase-root': {
                                                width: "100%",
                                                fontFamily: "Roboto",
                                                padding: "10px 10px 10px 0",
                                                borderBottom: "1px solid #f2f2f2",
                                                outline: "none",
                                            },
                                            '.MuiFormLabel-root': {
                                                margin: "0 !important",
                                                position: "relative",
                                                textAlign: "left",
                                                maxWidth: "100%",
                                                transform: "none",
                                                fontFamily: "Roboto",
                                                fontWeight: "500",
                                                fontSize: "10px",
                                                lineHeight: "11px",
                                                color: "#828282",
                                            },
                                            '.MuiFormLabel-root.Mui-focused':{
                                                color: "#828282",
                                            },
                                            '.MuiInputBase-root.Mui-focused fieldset': {
                                                border: "none",
                                                borderBottom: "1px solid #000",
                                                borderRadius: "0",
                                                borderTop: "0",
                                                borderRight: "0",
                                                borderLeft: "0"
                                            }
                                        }}/>}
                                    />
                                </LocalizationProvider>
                                {errorsDni.errorFechaExpedicion && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errorsDni.errorFechaExpedicionMsg}</span>}
                            </div>
                            <div className="cdc-documents-file-container">
                                {stateDniContentFront ?
                                    <>
                                        {/*<div>
                                            <FilePresentIcon style={{ fill: '#304D30', width: '50px', height: '50px' }}/>
                                            <p>Primer archivo adjuntado</p>
                                        </div>
                                        <button type="button" onClick={handleDeleteContentFront}><DeleteOutlineIcon style={{ fill: '#304D30'}}/></button>*/}
                                        <div className="flex-container align-center" style={{backgroundColor: "rgba(0, 0, 0, 0.04)", justifyContent: "space-between", padding: "0 5px"}}>
                                            <div className="flex-container align-center">
                                                <FilePresentIcon style={{ fill: '#304D30', width: '30px', height: '30px' }}/>
                                                <p>Primer archivo adjuntado</p>
                                            </div>
                                            <button className="cdc-documents-file-delete" type="button" style={{marginLeft: "5px"}} onClick={handleDeleteContentFront}><DeleteOutlineIcon style={{ fill: '#304D30'}}/></button>
                                        </div>
                                    </>
                                    :
                                    <>
                                    {loadingCompress ?
                                            <Box p={3} align="center">
                                                <CircularProgress />
                                                <p>Comprimiendo imagen...</p>
                                            </Box>
                                        : 
                                        <>
                                            <button type="button" className="cdc-documents-subir" >
                                                <input className="cdc-documents-subir-inp" id="cdc-file-new-front" type="file" name="cdc-file-new-front" onChange={handleChangeFrontFile}/>
                                                <label htmlFor="cdc-file-new-front" className="cdc-documents-subir-label">
                                                    <DriveFolderUploadIcon style={{ fill: '#304D30' }} alt={"Subir nuevo documento"}/>
                                                    Subir 1º Cara del Dni
                                                </label>
                                            </button>
                                            {errorsDni.errorContentFront && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errorsDni.errorContentFrontMsg}</span>}
                                        </>
                                    }
                                    </>
                                }
                            </div>
                            {showNewFile ?
                                <div className="cdc-documents-file-container">
                                    {stateDniContentBack ?
                                        <>
                                            {/*<div>
                                                <FilePresentIcon style={{ fill: '#304D30', width: '50px', height: '50px' }}/>
                                                <p>Segundo archivo adjuntado</p>
                                            </div>
                                            <button type="button" onClick={handleDeleteContentBack}><DeleteOutlineIcon style={{ fill: '#304D30'}}/></button>*/}
                                            <div className="flex-container align-center" style={{backgroundColor: "rgba(0, 0, 0, 0.04)", justifyContent: "space-between", padding: "0 5px"}}>
                                                <div className="flex-container align-center">
                                                    <FilePresentIcon style={{ fill: '#304D30', width: '30px', height: '30px' }}/>
                                                    <p>Segundo archivo adjuntado</p>
                                                </div>
                                                <button className="cdc-documents-file-delete" type="button" style={{marginLeft: "5px"}} onClick={handleDeleteContentBack}><DeleteOutlineIcon style={{ fill: '#304D30'}}/></button>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {loadingCompressSec ?
                                                <Box p={3} align="center">
                                                    <CircularProgress />
                                                    <p>Comprimiendo imagen...</p>
                                                </Box>
                                            : 
                                            <>
                                                <button type="button" className="cdc-documents-subir" >
                                                    <input className="cdc-documents-subir-inp" id="cdc-file-new-back" type="file" name="cdc-file-new-back" onChange={handleChangeBackFile}/>
                                                    <label htmlFor="cdc-file-new-back" className="cdc-documents-subir-label">
                                                        <DriveFolderUploadIcon style={{ fill: '#304D30' }} alt={"Subir nuevo documento"}/>
                                                        Subir 2º Cara del Dni
                                                    </label>
                                                </button>
                                                {errorsDni.errorContentBack && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errorsDni.errorContentBackMsg}</span>}
                                            </>
                                            }
                                        </>
                                    }
                                </div>
                                :
                                <button type="button" className="cdc-documents-modal-add" onClick={changeAddNewFile}>
                                    <AddBoxIcon style={{ fill: '#304D30' }}/>
                                    <p>Añadir segundo archivo</p>
                                </button>
                                    
                            }

                            {errorsDni.errorNombre && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errorsDni.errorNombreMsg}</span>}
                                            
                            
                            <DialogActions className="cdc-documents-modal-close">
                                { pending ?
                                    <>
                                        <p style={{fontSize: "18px", color: "#304D30", fontWeight: "600"}}>Enviando...</p>
                                    </>
                                    :
                                    <>
                                        <button type="button" onClick={handleCloseDni} variant="contained">Cerrar</button>
                                        <button type="submit" disabled={loadingCompress || loadingCompressSec}>Guardar</button>
                                    </>
                                }
                                
                            </DialogActions>
                        </Box>
                    </DialogContent>
                </Dialog>

                <Dialog open={openDniSuccess} onClose={handleCloseDniResponse} className="cdc-documents-modal-success">
                    <DialogContent className="cdc-documents-modal">
                        <Box>
                            <DialogContentText style={{marginBottom: "20px"}}>¡Se ha enviado el DNI!</DialogContentText>
                            
                            <DialogActions className="cdc-documents-modal-close"> 
                                <button type="button" onClick={handleCloseDniResponse} variant="contained">Cerrar</button>
                            </DialogActions>
                        </Box>
                    </DialogContent>
                </Dialog>
                <Dialog open={openDniFail} onClose={handleCloseDniResponse} className="cdc-documents-modal-fail">
                    <DialogContent className="cdc-documents-modal">
                        <Box>
                            <DialogContentText style={{marginBottom: "20px"}}>¡Vaya, parece que algo ha fallado!</DialogContentText>
                            <DialogContentText style={{marginBottom: "20px"}}>Por favor, vuelve a intentarlo más tarde.</DialogContentText>
                            
                            <DialogActions className="cdc-documents-modal-close"> 
                                <button type="button" onClick={handleCloseDniResponse} variant="contained">Cerrar</button>
                            </DialogActions>
                        </Box>
                    </DialogContent>
                </Dialog>

            </Grid>

            }
            <div className="doc-counter" style={{display: 'none'}} ref={countInput}>1</div>
        </>
    );
}