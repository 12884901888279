import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import { useLocation } from "react-router-dom";

export const DocumentLegal = () => {
    const {state} = useLocation();

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const scale = window.innerWidth < 767 ? 1 : 4.1666;
    const [currentPath, setCurrentPath] = useState(useLocation().pathname)

    const buttonStyle = currentPath === "/documentos-legal" ? {
        backgroundColor: "#619428",
        marginRight: 0
      }:{
        backgroundColor: "#304D30",
        marginRight: 0
      };

    const onDocumentLoadSuccess = ({numPages, pageNumber}) => {
        if((pageNumber > numPages) || (pageNumber === undefined)){
            setPageNumber(1);
        }
        setNumPages(numPages);
    }

    const LoadingPdf = () => {
        return(
            <div>Cargando el PDF...</div>
        );
    }
    return(
        <>
        {/*<div>
            <iframe className="public-iframe" src={state} width="100%" height="100%">
                <p>Your browser does not support iframes.</p>
            </iframe>
        </div>*/}
                <div>
                    <Document file={state} loading={LoadingPdf} onLoadSuccess={onDocumentLoadSuccess} onLoadError={(error) => console.log("Inside Error", error)} >
                        <Page pageNumber={pageNumber} renderTextLayer={false} scale={scale}/>
                    </Document>
                </div>
                <p  className="pagina-contador" style={{fontWeight: 600, display: "flex", justifyContent: "center"}}>
                    Página {pageNumber} de {numPages}
                </p>
                {numPages > 1 &&
                    <div className="flex-container" style={{marginTop:"20px", justifyContent: "space-around"}}>
                        {pageNumber > 1  &&
                            <button className="pdf-button prev" style={buttonStyle} onClick={() => {
                                if(pageNumber > 1){
                                    setPageNumber(pageNumber - 1);
                                }
                            }}>
                                Página Anterior
                            </button>
                        }
                        {numPages > pageNumber &&
                            <button className="pdf-button next" style={buttonStyle} onClick={() => {
                                if(numPages > pageNumber){
                                    setPageNumber(pageNumber + 1);
                                }
                            }}>
                                Página Siguiente
                            </button>
                        }
                    </div>
                }
                {/*<iframe className="cdc-documents-iframe-pdf" src={file} width="100%" height="100%"></iframe>*/}
        </>
    );
}