import React from 'react';
import { Route, Routes, BrowserRouter as Router, Link } from "react-router-dom";
import { Home } from './pages/home/Home';
import './App.css';
import Login from './pages/login/Login';
import Welcome from './pages/welcome/Welcome'
import Install from './pages/install/Install'
import AuthProvider from './firebase/Auth';
import { PrivateRoute } from './routes/PrivateRoutes';
import { Blogs } from './pages/blogs/Blogs';
import { BlogDetails } from './pages/blogs/BlogDetails';
import { Landing } from './pages/public-pages/landing/Landing';
import MainComponent from './components/main/Main';
import HeaderComponent from './components/header/Header';
import PublicHeaderComponent from './components/public-components/header/Header';
import PublicFooterComponent from './components/public-components/footer/Footer';
import { PBlogs } from './pages/public-pages/blogs/Blogs';
import InstallationProvider, { useInstallation } from './routes/InstallationProvider';
import { Notification } from './firebase/Notification';
import { PremiumForm } from './pages/premiumform/PremiumForm';
import Register from './pages/register/Register';
import Contact from './pages/contact/Contact';
import Forgetpasswd from './pages/forgetpasswd/Forgetpasswd';
import ResetpasswdForm from './pages/resetpasswd/Resetpasswd';
import BOLogin from './pages/backoffice/Login';
import {HomeBackoffice} from './pages/backoffice/HomeBackoffice';
import { JoinForm } from './pages/public-pages/join/JoinForm';
import { PBlogDetails } from './pages/public-pages/blogs/BlogDetails';
import { PRaffles } from './pages/public-pages/raffles/Raffles';
import { PRafflesDetails } from './pages/public-pages/raffles/RafflesDetails';
import { POffers } from './pages/public-pages/offers/Offers';
import { POfferDetails } from './pages/public-pages/offers/OfferDetails';
import { DocumentLegal } from './pages/public-pages/legaldocuments/DocumentLegal';
import { Raffles } from './pages/raffles/Raffles';
import { Managements } from './pages/managements/Managements';
import { Profile } from './pages/profile/Profile';
import {BackofficeRoute} from './routes/BackofficeRoutes'
import { RafflesExclusive } from './pages/raffles/RafflesExclusive';
import {License} from './pages/license/License';
import Invite from './pages/invite/Invite';
import Notifications from './pages/notifications/Notifications';
import { MyRaffles } from './pages/raffles/MyRaffles';
import { Documents } from './pages/documents/Documents';
import { Insurance } from './pages/insurance/Insurance';
import { PremiumRoute } from './routes/PremiumRoutes';
import { RafflesPublic } from './pages/raffles/RafflesPublic';
import { RaffleDetails } from './pages/raffles/RaffleDetails';
import { DocumentDetail } from './pages/documents/DocumentDetail';
import { Pending } from './components/pending/Pending';
import Tickets from './pages/tickets/Tickets';
import TicketDetail from './pages/tickets/TicketDetail';
import ContextProvider from './components/backoffice/context/ContextProvider';
import { Magazine } from './pages/public-pages/magazine/Magazine';
import { Managment } from './pages/public-pages/managment/Managment';
import { MarketPlace } from './pages/public-pages/marketplace/MarketPlace';
import { Payment } from './pages/public-pages/payment/Payment'; 
import { PaymentInstalled } from  './pages/payment/PaymentInstalled';
import { NotificationRedsys } from  './pages/notifications/NotificationRedsys';
import { CookiesPopUp } from './utils/CookiesPopUp';
import { Offers } from './pages/offers/Offers';
import { OfferDetails } from './pages/offers/OfferDetails';
import { PaymentResult } from './pages/public-pages/payment/PaymentResult';
import Wrapper from './components/wrapperskeleton/Wrapper';


function App() {


  const PublicSkeleton = (Component, props) => (
    <>
      <PublicHeaderComponent />
        <CookiesPopUp/>
        <Component {...props} />
      <PublicFooterComponent />
    </>
  );

  const Skeleton = (Component, props) => (
    <>
      <HeaderComponent {...props}/>
      <MainComponent>
        <Component {...props}/>
        <Notification />
      </MainComponent>
    </>
  );

  const WrapperSkeleton = (Component, props) => (
    <>
      <Wrapper {...props}>
        <Component {...props}/>
      </Wrapper>
    </>
  );


  const HomeComponent = () =>
    Skeleton(Home, {page: "home"});
  const BlogsComponent = () =>
    Skeleton(Blogs, {page: "noticias"});
  const BlogDetailsComponent = () =>
    Skeleton(BlogDetails, {page: "noticia"});
  const RafflesPublicComponent = ({categoryId}) =>
    Skeleton(RafflesPublic, {page: "sorteos", categoryId: categoryId});
  const RafflesExclusiveComponent = () =>
    Skeleton(RafflesExclusive, {page: "sorteos"});
  const RafflesComponent = () =>
    Skeleton(Raffles, {page: "sorteos"});
  const RaffleComponent = () =>
    Skeleton(RaffleDetails, {page: "sorteos"});
  const MyRafflesComponent = () =>
    Skeleton(MyRaffles, {page: "mis_sorteos"});
  const ManagementComponent = () =>
    Skeleton(Managements, {page: "servicios"});
  const ProfileComponent = () =>
    Skeleton(Profile, {page: "perfil"});
  const ContactComponent = () =>
    Skeleton(Contact, {page: "contacto"});
  const PremiumComponent = () =>
    Skeleton(PremiumForm, {page: "hacerse_socio"});
  const PendingComponent = () =>
    Skeleton(Pending, {page: "hacerse_socio"});
  const LicenseComponent = () =>
    Skeleton(License, {page: "licencias"});
  const InsuranceComponent = () =>
    Skeleton(Insurance, {page: "seguros"});
  const InviteComponent = () =>
    Skeleton(Invite, {page: "invitar_amigo"});
  const NotificationsComponent = () =>
    Skeleton(Notifications, {page: "notificaciones"});
  const MyDocumentsComponent = () =>
    Skeleton(Documents, {page: "documentacion"});
  const DocumentDetailComponent = () =>
    Skeleton(DocumentDetail, {page: "documentacion"});
  const GestionesComponent = () =>
    Skeleton(Tickets, {page: "mis_gestiones"});
  const GestionesDetailComponent = () =>
    Skeleton(TicketDetail, {page: "detalle_gestion"});
  const DocumentLegalComponent = () =>
    Skeleton(DocumentLegal, {page: "documentos_legales"});
  const PaymentComponent = () =>
    Skeleton(PaymentInstalled, {page: "payment"});
  const NotificationRedsysComponent = () =>
    Skeleton(NotificationRedsys, {page: "notificationredsys"});
  const OffersComponent = () =>
    Skeleton(Offers, {page: "ofertas"});
  const OfferDetailsComponent = () =>
    Skeleton(OfferDetails, {page: "oferta"});

  const DocumentLegalWrapper = () =>
    WrapperSkeleton(DocumentLegal, {page: "documentos_legales"});

 const RouterComponent= () => {

 /*const { installed } = useInstallation();
 
  if(installed){*/
    return (
      <Router>
        <Routes>
          <Route path="/" element={
              <PrivateRoute>
                <HomeComponent />
              </PrivateRoute>
            }
          />
            <Route path="/home" element={
              <PrivateRoute>
                <HomeComponent />
              </PrivateRoute>
            }
          />
          <Route path="/blogs" element={
              <PrivateRoute>
                <BlogsComponent />
              </PrivateRoute>
            }
          />
          <Route path="/blog/:id" element={
              <PrivateRoute>
                <BlogDetailsComponent />
              </PrivateRoute>
            }
          />
          <Route path="/sorteos" element={
              <PrivateRoute>
                <RafflesComponent />
              </PrivateRoute>
            }
          />
          <Route path="/sorteos/tierraDeCaza" element={
              <PrivateRoute>
                <RafflesPublicComponent categoryId={34}/>
              </PrivateRoute>
            }
          />
          <Route path="/sorteos/exclusivo" element={
              <PrivateRoute>
                <RafflesExclusiveComponent />
              </PrivateRoute>
            }
          />
           <Route path="/sorteos/reserva" element={
              <PrivateRoute>
                <RafflesPublicComponent categoryId={33}/>
              </PrivateRoute>
            }
          />
          <Route path="/sorteo/:id" element={
              <PrivateRoute>
                <RaffleComponent />
              </PrivateRoute>
            }
          />
          <Route path="/mis-sorteos" element={
              <PrivateRoute>
                <MyRafflesComponent />
              </PrivateRoute>
            }
          />
          <Route path="/mis-documentos" element={
              <PrivateRoute>
                <MyDocumentsComponent />
              </PrivateRoute>
            }
          />
          <Route path="/documento/:id" element={
              <PrivateRoute>
                <DocumentDetailComponent />
              </PrivateRoute>
            }
          />
          <Route path="/servicios" element={
              <PrivateRoute>
                <ManagementComponent />
              </PrivateRoute>
            }
          />
          <Route path="/mi-perfil" element={
              <PrivateRoute>
                <ProfileComponent />
              </PrivateRoute>
            }
          />
          <Route path="/contacto" element={
              <PrivateRoute>
                <ContactComponent />
              </PrivateRoute>
            }
          />
          <Route path="/hacerse-socio" element={
              <PrivateRoute>
                <PremiumRoute>
                  <PremiumComponent />
                </PremiumRoute>
              </PrivateRoute>
            }
          />
          <Route path="/hacerse-socio/pendiente" element={
              <PrivateRoute>
                <PendingComponent />
              </PrivateRoute>
            }
          />
          <Route path="/licencias" element={
              <PrivateRoute>
                <LicenseComponent />
              </PrivateRoute>
            }
          />
          <Route path="/seguros" element={
              <PrivateRoute>
                <InsuranceComponent />
              </PrivateRoute>
            }
          />
          <Route path="/invitar-amigo" element={
              <PrivateRoute>
                <InviteComponent />
              </PrivateRoute>
            }
          />
          <Route path="/notificaciones" element={
              <PrivateRoute>
                <NotificationsComponent />
              </PrivateRoute>
            }
          />
          <Route path="/mis-gestiones" element={
              <PrivateRoute>
                <GestionesComponent />
              </PrivateRoute>
            }
          />
           <Route path="/detalle-gestion" element={
              <PrivateRoute>
                <GestionesDetailComponent />
              </PrivateRoute>
            }
          />
          <Route path="/documentos-legales" element={
            <PrivateRoute>
              <DocumentLegalComponent/>
            </PrivateRoute>
          } 
          />
          <Route path="/documentos-publicos-legales" element={
            <DocumentLegalWrapper />
          } 
          />
          <Route path="/payment" element={
            <PrivateRoute>
              <PaymentComponent/>
            </PrivateRoute>
          } 
          />
          <Route path="/notificationRedsys" element={
            <PrivateRoute>
              <NotificationRedsysComponent/>
            </PrivateRoute>
          } 
          />
          <Route path="/ofertas" element={
              <PrivateRoute>
                <OffersComponent />
              </PrivateRoute>
            }
          />
          <Route path="/oferta/:id" element={
              <PrivateRoute>
                <OfferDetailsComponent />
              </PrivateRoute>
            }
          />
            <Route path="/install" exact element={<Install />} />
            <Route path="/login" exact element={<Login />} />
            <Route path="/welcome" exact element={<Welcome />} />
            <Route path="/registro" exact element={<Register />} />
            <Route path="/olvido-contrasena" exact element={<Forgetpasswd />} />
            <Route path="/reestablecer-contrasena" exact element={<ResetpasswdForm />} />
            <Route path="*" exact element={<><div>Página no encontrada</div><Link to="/">Volver a la página de inicio</Link></>} />
        </Routes>
      </Router>
    )
  /*}else{
    return(
      
      <ContextProvider>
        
        <Router>
        
          <Routes>
            <Route path="/" element={
                PublicSkeleton(Landing)
              }
            />
            <Route path="/index.html" exact element={PublicSkeleton(Landing)} />
            <Route path="/home" exact element={PublicSkeleton(Landing)} />
            <Route path="/blogs" exact element={PublicSkeleton(PBlogs)} />
            <Route path="/blog/:id" exact element={PublicSkeleton(PBlogDetails)} />
            <Route path="/sorteos" exact element={PublicSkeleton(PRaffles)} />
            <Route path="/sorteo/:id" exact element={PublicSkeleton(PRafflesDetails)} />
            <Route path="/ofertas" exact element={PublicSkeleton(POffers)} />
            <Route path="/oferta/:id" exact element={PublicSkeleton(POfferDetails)} />
            <Route path="/hacerse-socio" exact element={PublicSkeleton(JoinForm)} />
            <Route path="/revista-trofeo" exact element={PublicSkeleton(Magazine)} />
            <Route path="/marketplace" exact element={PublicSkeleton(MarketPlace)} />
            <Route path="/gestoria" exact element={PublicSkeleton(Managment)} />
            <Route path="/pago" exact element={PublicSkeleton(Payment)} />
            <Route path="/pago-resultado" exact element={PublicSkeleton(PaymentResult)} />
            <Route path="/payment" exact element={PublicSkeleton(PaymentResult)} />
            <Route path="/documentos-legal" element={PublicSkeleton(DocumentLegal)} />
            <Route path="/backoffice-login" exact element={<BOLogin />} />
              <Route path="/backoffice-home/*" element={
                <BackofficeRoute>
                  <HomeBackoffice />
                </BackofficeRoute> 
              }/>
            <Route path="*" exact element={<><div>Página no encontrada</div><a href="/home">Volver a la página de inicio</a></>} />
          </Routes>
        </Router>
      </ContextProvider>
    )
  }*/
}

  return (
    <InstallationProvider>
      <AuthProvider>
        <RouterComponent />
      </AuthProvider>
    </InstallationProvider>
  );
}

export default App;
