import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Pagination, Stack } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Join } from "../../components/join/Join";
import { useAuth } from "../../firebase/Auth";
import { instance } from "../../utils/axios-config";
import { format } from "date-fns";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import ordenar from "../../assets/ordenar.svg";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { compressImageUpload, validFileSize } from '../../services/Commons';

export default function Tickets() {
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [pending, setPending] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [text, setText] = useState();
    const [gestiones, setGestiones] = useState([]);
    const [files, setFiles] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalGestiones, setTotalGestiones] = useState([]);
    const [sortBy, setSortBy] = useState(true);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [errors, setErrors] = useState({});
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const countInput = useRef();
    const [loadingCompress, setLoadingCompress] = useState(false);
    const [formDataId, setFormDataId] = useState(currentUser.pk);
    const [formDataTipoGestion, setFormDataTipoGestion] = useState("Incidencia");
    const [formDataTitulo, setFormDataTitulo] = useState("");
    const [formDataMensaje, setFormDataMensaje] = useState("");


    const handleCloseCancel = (e) => {
        e.preventDefault();
        countInput.current.innerText = parseInt(countInput.current.innerText) + 1;
        setLoadingCompress(false);
        setOpen(false);
    };

    const handleOpenDialog = (e) => {
        e.preventDefault();
        setLoadingCompress(false);
        setOpen(true);
    };

    const handleSubmitCreate = async(e) => {
        e.preventDefault();
        setErrors({});
            if(validateForm()){
                addNewTicket();
            }

    }

    const handleNavigateTicketDetail = async(event, idGestion) => {
        event.preventDefault();
        navigate('/detalle-gestion', {
            state: {
                id: idGestion
            }
        });
    }

    const validFileType = (file) => {
        let fileType = null;
        let validType = false;
        try{
            fileType = file.type.split("/").pop();
            if(fileType === "pdf" || fileType === "jpg" || fileType === "png" || fileType === "jpeg"){
                validType = true;
            }
        }catch(error){
            setErrors(errors => ({...errors, errorFile: true, errorFileMsg: "Se ha producido un error al comprobar el archivo."}));
        }finally{
            return validType;
        }
    }

    const validateForm = () =>{
        let valid = true;
        setErrors({});
            if(formDataTitulo === undefined || formDataTitulo === ''){
                setErrors(errors => ({...errors, errorTitulo: true, errorTituloMsg: "El título no puede estar vacío."}));
                valid = false;
            }
            if(formDataMensaje === undefined || formDataMensaje === ''){
                setErrors(errors => ({...errors, errorMensaje: true, errorMensajeMsg: "El mensaje no puede estar vacío."}));
                valid = false;
            }

            if(!valid){
                window.scrollTo({top:0,left:0, behavior: "smooth"});
            }
            return valid;
    }

    const handleChangeFile = async (e) => {
        let previusCount = parseInt(countInput.current.innerText);
        let validType = validFileType(e.target.files[0]);
        let validSize = validFileSize(e.target.files[0]);

        if(!validType){
            setErrors(errors => ({...errors, errorFile: true, errorFileMsg: "El formato del archivo debe ser jpeg, png o pdf."}));
        }else if(validType && "pdf" === e.target.files[0].type.split("/").pop()){
            if(validSize){
                readFile(e.target.files[0]);
            }else{
                setErrors(errors => ({...errors, errorContent: true, errorContentMsg: "El tamaño del archivo es demasiado grande. Max: 2MB"}));
            }
        }else if(validType && "pdf" !== e.target.files[0].type.split("/").pop()){
            if(validSize){
                readFile(e.target.files[0]);
            }else{
                let result = e.target.files[0];
                try{
                    setLoadingCompress(true);
                    result = await compressImageUpload(e.target.files[0]);
                }catch(error){

                }finally{
                    if(previusCount === parseInt(countInput.current.innerText)){
                        setLoadingCompress(false);
                    }
                }
                if(previusCount === parseInt(countInput.current.innerText)){
                    readFile(result);
                }
            }
        }

        /*if(validFileType(e.target.files[0])){
            readFile(e.target.files[0]);
        }else{
            setErrors(errors => ({...errors, errorFile: true, errorFileMsg: "El formato del archivo debe ser jpeg, png o pdf."}));
        }*/
        
    };

    const readFile = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            
            let temp = [];
            temp = files;
            temp.push({
                content: reader.result.split(",")[1],
                formato: "." + file.type.split("/").pop(),
                name: file.name
            });
            setFiles(temp);
            setErrors(errors => ({...errors, errorFile: false, errorFileMsg: ""}));
            
        };
        reader.onerror = function (error) {
            setErrors(errors => ({...errors, errorFile: true, errorfileMsg: "Vaya, parece que el algo ha salido mal..."}));
        };
    }

    const handleDeleteFile = (fileName) => {
        let temp = files.filter(function(f) { return f.name !== fileName });
        setFiles(temp);
    };

    useEffect(() => {
        if(currentUser.tipo.toLowerCase() === "premium"){
            setText(searchParams.get('text') ? searchParams.get('text') : "");
            loadPageData(1, text);
        }
    }, [searchParams]);

    const sortDateTickets = () => {
        setPage(1);
        if(sortBy){
            let totalGestis = totalGestiones?.map(obj => {return {...obj, date: new Date(obj.fechaCreacion)}}).sort((a, b) => b.date - a.date);
            let gestis = totalGestis.slice(0, 10);
            setGestiones(gestis);
            setSortBy(!sortBy);
        }else{
            let totalGestis = totalGestiones?.map(obj => {return {...obj, date: new Date(obj.fechaCreacion)}}).sort((a, b) => a.date - b.date);
            let gestis = totalGestis.slice(0, 10);
            setGestiones(gestis);
            setSortBy(!sortBy);
        }
    }
    

    const loadPageData = useCallback (async (page = 1, text) => {

        let buildUrl = `/gestiones/gestionesByUser/${currentUser.pk}`;
        
        instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
        
        await instance.get(buildUrl).then(function(resp) {
            if(error) setError(false);
            let gestis = [];
            if(resp.data.data !== undefined){
                gestis = resp.data.data.slice(((page - 1)*10), ((page)*10));
                setTotalGestiones(resp.data.data);
                setGestiones(gestis?.map(obj => {return {...obj, date: new Date(obj.fechaCreacion)}}).sort((a, b) => b.date - a.date));
                setTotalPages(Math.ceil(resp.data.data.length/10));
            }else{
                gestis = resp.data.slice(((page - 1)*10), ((page)*10));
                setTotalGestiones(resp.data.data);
                setGestiones(gestis);
                setTotalPages(Math.ceil(resp.data.data.length/10));
            }
        })
        .catch(function(resp) {
            setError(true);
            Swal.fire({
                icon: "error",
                title: "error",
                text: "Se ha producido un error inesperado, vuelve a intentarlo más tarde.",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Aceptar"
            })
        }).finally(() => {
            setLoading(false);
            if (initialLoading) setInitialLoading(false);
        });
    }, [initialLoading]);

    const addNewTicket = async() => {
        try {
            setPending(true);
            let url = `/gestiones/newGestion`;
            let body = {
                idUsuario: formDataId,
                tipoGestion: formDataTipoGestion,
                titulo: formDataTitulo,
                mensaje: formDataMensaje,
            };
            
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}

            await instance.post(url, body).then((response) => {
                let bodyFile;
                let allFiles = [];
                files.map((f) => {
                    bodyFile = {
                        idUsuario: currentUser.pk,
                        idGestion: response.data.idGestion,
                        nombre: "Archivo adjunto",
                        formato: f.formato,
                        content: f.content
                    }
                    allFiles.push(instance.post(`/gestiones/attachToThread/${response.data.thread.idThread}`, bodyFile));
                });
                Promise.all(allFiles).then(function(values) {
                    setOpen(false);
                    Swal.fire({
                        icon: 'success',
                        title: 'Mensaje creado correctamente',
                        showConfirmButton: false,
                        timer: 2500
                        })
                }).catch(function(e) {
                    setOpen(false);
                    Swal.fire({
                        icon: "error",
                        title: "error",
                        text: "Se ha producido un error inesperado al enviar el adjunto",
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: "Aceptar"
                    });
                });
            });
            loadPageData(1, text);
        }catch (error) {
            Swal.fire({
                icon: "error",
                title: "error",
                text: "Se ha producido un error inesperado al crear la solicitud, intentalo más tarde",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Aceptar"
            });
        }finally{
            setPending(false);
        }
    }

    const handlePageChange = (event, page) => {
        let notis = totalGestiones.slice(((page - 1)*10), ((page)*10));
        setPage(page);
        setGestiones(notis);
        //loadPageData(page, text, category);
    };

    return(
        <>
            {currentUser.tipo.toLowerCase() === "basic" ? <Join text={"Gestiones"}/> :
                <Box className="cdc-gestiones">

                {initialLoading ? 
                    (
                        <Box p={3} align="center">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            {loading ? 
                                (
                                    <Box p={3} align="center">
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <>
                                        {!error ?
                                            <>
                                                    <>
                                                        <div className="cdc-gestiones-options">
                                                            <button className="cdc-gestiones-options-sort" type="button" onClick={sortDateTickets}>
                                                                <figure>
                                                                    <img src={ordenar} alt="Ordenar Gestiones" />
                                                                </figure>
                                                                <p>Ordenar fecha asc/desc</p>
                                                            </button>
                                                            <button className="cdc-gestiones-options-sort" type="button" onClick={handleOpenDialog}>
                                                                <AddCommentOutlinedIcon style={{fill: '#304D30'}}/>
                                                                <p>Añadir Nuevo Ticket</p>
                                                            </button>
                                                        </div>

                                                        <Dialog open={open} onClose={handleCloseCancel} className="gestiones-dialog">
                                                            <DialogContent className="gestiones-dialog-content">
                                                                <DialogActions className="gestiones-dialog-close">
                                                                    <a href="#" onClick={handleCloseCancel} variant="contained">X</a>
                                                                </DialogActions>
                                                                <div className="gestiones-dialog-content-form">
                                                                        <DialogContentText className="gestiones-dialog-content-title">
                                                                            Nuevo Ticket
                                                                        </DialogContentText>
                                                                        <p className="gestiones-dialog-content-subtitle">Rellena los campos necesarios para generar un ticket sobre la cuestión que desea tratar:</p>
                                                                        <div>
                                                                            <div className="cdc-contact-input">
                                                                                <label htmlFor="tipoGestion">TIPO DE GESTIÓN*</label>
                                                                                <select className="cdc-profile-table-select" name="gestion" id="gestion" onChange={(e) => {
                                                                                        setFormDataTipoGestion(e.target.value);
                                                                                    }}>
                                                                                    <option value="Incidencia">Incidencia</option>
                                                                                    <option value="Consulta">Consulta</option>
                                                                                    <option value="Licencias">Licencias</option>
                                                                                    <option value="Sorteos">Sorteos</option>
                                                                                    <option value="Seguros">Seguros</option>
                                                                                    <option value="Perfil">Perfil</option>
                                                                                    <option value="Documentación">Documentación</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="cdc-contact-input">
                                                                                <label htmlFor="titulo">TÍTULO*</label>
                                                                                <input onChange={(e) => {
                                                                                        setFormDataTitulo(e.target.value);
                                                                                    }}
                                                                                    type="text"
                                                                                    name="titulo"
                                                                                    placeholder="Título"
                                                                                    className={errors.errorTitulo ? "cdc-red-flag": ""}
                                                                                />
                                                                                {/*errors.errorTitulo && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorTituloMsg}</span>*/}
                                                                            </div>
                                                                            
                                                                            <div className="cdc-contact-input">
                                                                                <label htmlFor="mensaje">MENSAJE*</label>
                                                                                <textarea
                                                                                style={{width: "100%"}}
                                                                                name="mensaje"
                                                                                onChange={(e) => {
                                                                                    setFormDataMensaje(e.target.value);
                                                                                }}
                                                                                className={errors.errorMensaje ? "cdc-red-flag": ""}
                                                                                />
                                                                                {/*errors.errorMensaje && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorMensajeMsg}</span>*/}
                                                                            </div>
                                                                            {files.length !== 1 ? 
                                                                                <div>
                                                                                    {loadingCompress ?
                                                                                        <Box p={3} align="center">
                                                                                            <CircularProgress />
                                                                                            <p>Comprimiendo imagen...</p>
                                                                                        </Box>
                                                                                    : 
                                                                                    <>
                                                                                        <button type="button" style={{display: "flex", position: "relative", backgroundColor: "transparent", border: "none", padding: 0}}>
                                                                                            <input id="cdc-ticket-upload" type="file" name="cdc-ticket-upload" onChange={handleChangeFile} onClick={(event)=> { event.target.value = null}} style={{visibility: "hidden"}}/>
                                                                                            <label htmlFor="cdc-ticket-upload" className="cdc-backoffice-file-label">
                                                                                                <DriveFolderUploadIcon style={{fill: "#304D30"}} alt={"Subir nuevo documento"}/>
                                                                                                <span style={{marginLeft: "5px", color: "#304D30"}}>Añadir archivo</span>
                                                                                            </label>
                                                                                        </button>
                                                                                        {errors.errorFile && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorFileMsg}</span>}
                                                                                    </>
                                                                                    }
                                                                                </div>
                                                                            :
                                                                                <div style={{marginBottom: "12px"}}>
                                                                                    {files.map((file, index) => {
                                                                                        return (
                                                                                            <div className="flex-container align-center" style={{backgroundColor: "rgba(0, 0, 0, 0.04)", justifyContent: "space-between", padding: "0 5px"}}>
                                                                                                <div key={"archivo-adjunto-" + index} className="flex-container align-center">
                                                                                                    <FilePresentIcon style={{ fill: '#304D30', width: '30px', height: '30px' }}/>
                                                                                                    <p>{file.name}</p>
                                                                                                </div>
                                                                                                <button className="cdc-backoffice-file-delete" type="button" style={{marginLeft: "5px"}} onClick={() => handleDeleteFile(file.name)}><DeleteOutlineIcon style={{ fill: '#304D30'}}/></button>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                    }
                                                                                </div>
                                                                            }

                                                                        </div>
                                                                </div>
                                                            </DialogContent>
                                                            <DialogActions className="passwd-reset-content-buttons">
                                                                {pending ?
                                                                    <Button className="passwd-reset-content-button" variant="contained" disabled={true}>Enviando...</Button>
                                                                    :
                                                                    <Button className="passwd-reset-content-button" variant="contained" onClick={handleSubmitCreate} disabled={loadingCompress}>Crear</Button>
                                                                }
                                                            </DialogActions>
                                                        </Dialog>

                                                        {gestiones.length > 0 && 
                                                        <ul className="cdc-gestiones-list">
                                                            {gestiones.map((newElement, index) => {
                                                                return (
                                                                    <>
                                                                        
                                                                        <li className={"cdc-gestiones-container"} key={"gestiones-" + index} onClick={(e) => {handleNavigateTicketDetail(e, newElement.idGestion)}}>
                                                                            <Box className="cdc-gestiones-container-right">
                                                                                <div>
                                                                                    <p className="cdc-gestiones-date">{newElement.fechaCreacion ? format(new Date(newElement.fechaCreacion), "dd/MM/yyyy") : "Fecha sin definir"}</p>
                                                                                    <h2 className="cdc-gestiones-title">{newElement.titulo ? newElement.titulo : "titulo sin definir"}</h2>
                                                                                    <p className="cdc-gestiones-summary">{newElement.status === "Close" ? "Cerrado":
                                                                                                                        newElement.status === "Pending" ? "Pendiente": "Nueva"}</p>
                                                                                </div>
                                                                                <p className="cdc-notificagestionestions-date cdc-gestiones-hhmm">{newElement.fechaCreacion ? format(new Date(newElement.fechaCreacion), "HH:mm") : "Hora sin definir"}</p>
                                                                            </Box>
                                                                        </li>
                                                                    </>
                                                                )
                                                            })}
                                                        </ul>
                                                        }

                                                    </>
                                                    {gestiones.length === 0 && <div className="cdc-raffles-notfound">No se han encontrado gestiones.</div>}
                                            </>
                                            :
                                            <div className="cdc-error-call">Se ha producido un error al intentar obtener la información. Vuelva a intentarlo más tarde.</div>
                                        }
                                    </>
                                )
                            }
                        </>
                    )}
                    <Box sx={{mb: 6, mt: 4}} align="center">
                        <Stack spacing={6} className="cdc-blog-pagination">
                            <Pagination count={totalPages} page={page} onChange={handlePageChange}/>
                        </Stack>
                        {/*<PPagination setLoading={setLoading} loadData={loadPageData} totalPages={totalPages} text={text} category={null}/>*/}
                    </Box>
                </Box>
            }
            <div className="doc-counter" style={{display: 'none'}} ref={countInput}>1</div>
        </>
    );
}
