import { Box, Button, Container, Typography } from "@mui/material";
import { useState } from "react";
import { instanceAWSbo } from "../../utils/axios-config";
import Swal from "sweetalert2";
import { useAuth } from "../../firebase/Auth";
import { Join } from "../../components/join/Join";
import { Link } from "react-router-dom";

export default function Invite() {

    const { currentUser } = useAuth();

    const copiarPortapapeles = () => {
        let aux = document.getElementById("invite-link").innerHTML;
        if (!navigator.clipboard){
            aux.select();
            document.execCommand("copy");
        } else{
            navigator.clipboard.writeText(aux).then(
                function(){
                    Swal.fire({
                        icon: 'success',
                        title: 'Enlace copiado en el portapapeles',
                        showConfirmButton: false,
                        timer: 1500
                      }) 
                })
              .catch(
                 function() {
                    Swal.fire({
                        icon: "error",
                        title: "error",
                        text: "Error al copiar el enlace promocional",
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: "Aceptar"
                    });
              });
        }    
    }

    const url = `${window.location.protocol}//server.tierradecaza.com/hacerse-socio?promoCode=${currentUser.promoCode}`;
    
    return(
        <>
        {currentUser.tipo.toLowerCase() === "basic" ? <Join text={"Invitar a un amigo"}/> :

            <Box className="cdc-invite">
                <h2>¡Invita a un amigo al Club Tierra de Caza!</h2>
                <p> 
                    Invita a un amigo usando el código asociado a tu perfil de usuario y podrás ganar, ¡20 puntos nuevos!
                    Si se da de alta en nuestro club
                </p>

                <div className="cdc-profile-banner-socio-premium">
                    <div className="cdc-profile-banner-premium">
                        <h4 className="cdc-profile-banner-texto-premium">Puntos Acumulados</h4>
                            <h1 className="cdc-profile-banner-texto-socio-puntos">{currentUser.puntos ? currentUser.puntos : "0"}</h1>
                    </div>
                    <div className="cdc-profile-banner-premium">
                        <h4 className="cdc-profile-banner-texto-premium">Código Promocional</h4>
                        <h1 className="cdc-profile-banner-texto-socio">{currentUser.promoCode ? currentUser.promoCode : "Por definir"}</h1>        
                    </div>
                </div>
                <p style={{color:"#B00020"}}>*Los puntos acumulados caducarán cada año*</p>
                {currentUser.promoCode ? 
                <div className="invite-section">
                    <p style={{display:"none"}} className="invite-link" id="invite-link">
                        {url}
                    </p>
                    <p className="invite-link" id="invite-link">
                        Puedes copiar y enviar este enlace a tu contacto y, cuando se de alta, te aplicaremos los puntos de forma automática.
                        Es el método mas sencillo, accederá de forma directa al formulario de alta.
                    </p>
                    <p className="invite-link" id="invite-link">
                        También le puedes enviar el código para que lo introduzca en el formulario de alta, te aplicaremos los puntos de forma automática.
                    </p>
                    <Button className='invite-button' onClick={copiarPortapapeles}>Copiar enlace promocional</Button>
                </div> 
                :
                <p>
                    Todavía no tienes ningún código promocional disponible a tu nombre, prueba a hacerte socio o ponte en contacto con nosotros.
                </p>
                }
            </Box>
        }
        </>
    );
}