import { useLocation } from "react-router-dom";
import {  useCallback, useEffect, useRef, useState } from "react";
import { instance } from "../../utils/axios-config";
import { useAuth } from "../../firebase/Auth";
import { Box } from "@mui/system";
import { format } from "date-fns";
import { CircularProgress ,Button, Dialog, DialogContent, DialogActions, DialogContentText, Link} from "@mui/material";
import ordenar from "../../assets/ordenar.svg";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PreviewDoc from "./PreviewDoc";
import ConfirmDialog from "./ConfirmDialog";
import { compressImageUpload, validFileSize } from "../../services/Commons";

export default function TicketDetail() {

    const location = useLocation();
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(true);
    const [loadingSend, setLoadingSend] = useState(false);
    const [attachment, setAttachment ] = useState(undefined);
    const [mensajes, setMensajes] = useState([]);
    const [mensaje, setMensaje] = useState(undefined);
    const [showMessage, setShowMessage] = useState(false);
    const [ openPreview, setOpenPreview ] = useState(false);
    const [ openResponse, setOpenResponse ] = useState({open: false, msg: ""});
    const [ typeFile, setTypeFile ] = useState();
    const [files, setFiles] = useState([]);
    const [totalMensajes, setTotalMensajes] = useState([]);
    const [sortBy, setSortBy] = useState(false);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [errors, setErrors] = useState({});
    const countInput = useRef();
    const [loadingCompress, setLoadingCompress] = useState(false);
    const [ticketData, setTicketData] = useState({
        idGestion: "",
        tipoGestion: "",
        titulo: "",
        fechaCreacion: "",
        estado: "",
    });
    const [formDataMensaje, setFormDataMensaje] = useState("");
    /*const [formData, setFormData] = useState({
        remitente: currentUser.pk,
        idUsuario: currentUser.pk,
        mensaje: "",
    });*/
    const idgestion = location.state.id;

    const handleCloseCancel = (e) => {
        e.preventDefault();
        setFormDataMensaje("");
        setFiles([]);
        countInput.current.innerText = parseInt(countInput.current.innerText) + 1;
        setOpen(false);
        setLoadingCompress(false);
    };

    const handleOpenDialog = (e) => {
        e.preventDefault();
        window.scrollTo({top:0,left:0, behavior: "smooth"});
        setOpen(true);
    };

    const handleSubmitCreate = async(e) => {
        e.preventDefault();
        setErrors({});
        if(validateForm()){
            addNewMessage();
        }
    }

    const addNewMessage = async () => {
        setLoadingSend(true);
        try {
            let url = `/gestiones/newMesage/${ticketData.idGestion}`;
            
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
            let body = {
                remitente: currentUser.pk,
                idUsuario: currentUser.pk,
                mensaje: formDataMensaje,
            };
    
            await instance.post(url, body).then( async (response) => {
                let bodyFile;
                let allFiles = [];
                files.map((f) => {
                    bodyFile = {
                        idUsuario: currentUser.pk,
                        idGestion: ticketData.idGestion,
                        nombre: "Archivo adjunto",
                        formato: f.formato,
                        content: f.content
                    }
                    allFiles.push(instance.post(`/gestiones/attachToThread/${response.data.idThread}`, bodyFile));
                });
                Promise.all(allFiles).then(function(values) {
                    setOpen(false);
                    setOpenResponse({open: true, msg: "Se ha enviado su mensaje correctamente. Le responderemos a la mayor brevedad posible."});

                }).catch(function(e) {
                    setOpen(false);
                    setOpenResponse({open: true, msg: "Se ha producido un error inesperado al enviar el adjunto."});
                }).finally(() =>{
                    setFormDataMensaje("");
                    setFiles([]);
                    loadPageData();
                });
            });
        } catch (error) {
            setOpenResponse({open: true, msg: "Se ha producido un error inesperado al intentar enviar el mensaje, inténtelo más tarde."});
        }finally{
            setLoadingSend(false);
        }
    }
    

    const validateForm = () =>{
        let valid = true;
        setErrors({});
            if(formDataMensaje === undefined || formDataMensaje === ''){
                setErrors(errors => ({...errors, errorMensaje: true, errorMensajeMsg: "El mensaje no es válido."}));
                valid = false;
            }
            if(!valid){
                window.scrollTo({top:0,left:0, behavior: "smooth"});
            }
            return valid;
    }

    const validFileType = (file) => {
        let fileType = null;
        let validType = false;
        try{
            fileType = file.type.split("/").pop();
            if(fileType === "pdf" || fileType === "jpg" || fileType === "png" || fileType === "jpeg"){
                validType = true;
            }
        }catch(error){
            setErrors(errors => ({...errors, errorFile: true, errorFileMsg: "Se ha producido un error al comprobar el archivo."}));
        }finally{
            return validType;
        }
    }

    const handleChangeFile = async (e) => {
        let previusCount = parseInt(countInput.current.innerText);
        let validType = validFileType(e.target.files[0]);
        let validSize = validFileSize(e.target.files[0]);

        if(!validType){
            setErrors(errors => ({...errors, errorFile: true, errorFileMsg: "El formato del archivo debe ser jpeg, png o pdf."}));
        }else if(validType && "pdf" === e.target.files[0].type.split("/").pop()){
            if(validSize){
                readFile(e.target.files[0]);
            }else{
                setErrors(errors => ({...errors, errorContent: true, errorContentMsg: "El tamaño del archivo es demasiado grande. Max: 2MB"}));
            }
        }else if(validType && "pdf" !== e.target.files[0].type.split("/").pop()){
            if(validSize){
                readFile(e.target.files[0]);
            }else{
                let result = e.target.files[0];
                try{
                    setLoadingCompress(true);
                    result = await compressImageUpload(e.target.files[0]);
                }catch(error){

                }finally{
                    if(previusCount === parseInt(countInput.current.innerText)){
                        setLoadingCompress(false);
                    }
                }
                if(previusCount === parseInt(countInput.current.innerText)){
                    readFile(result);
                }
            }
        }

        /*if(validFileType(e.target.files[0])){
            
        }else{
            setErrors(errors => ({...errors, errorFile: true, errorFileMsg: "El formato del archivo debe ser jpeg, png o pdf."}));
        }*/
        
    };

    const readFile = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            
            let temp = [];
            temp = files;
            temp.push({
                content: reader.result.split(",")[1],
                formato: "." + file.type.split("/").pop(),
                name: file.name
            });
            setFiles(temp);
            setErrors(errors => ({...errors, errorFile: false, errorFileMsg: ""}));
            
        };
        reader.onerror = function (error) {
            setErrors(errors => ({...errors, errorFile: true, errorfileMsg: "Vaya, parece que el algo ha salido mal..."}));
        };
    }

    const handleDeleteFile = (fileName) => {
        let temp = files.filter(function(f) { return f.name !== fileName });
        setFiles(temp);
    };
    
    
    useEffect(() => {
        loadPageData();
    }, []);

    const handleLoadFiles = async (mensaje) => {
        if(mensaje !== undefined){
            if(mensaje.attach !== undefined){
                setLoading(true);
                instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
                try{
                    await instance.get(`/gestiones/getAttach/${encodeURIComponent(mensaje.attach)}`).then(function(resp){
                        let arr = mensaje.attach.split("/"); 
                        let txt = arr[arr.length -1];
                        let arrTxt = txt.split(".");
                        setTypeFile(arrTxt[arrTxt.length -1]);
                        setAttachment(resp.data);
                    });
                }catch(error){
                    console.log(error);
                }finally{
                    setLoading(false);
                }
            }else{
                setAttachment(undefined);
                setLoading(false);
            }
        }else{
            setAttachment(undefined);
            setLoading(false);
        }
    }

    const handleMensajeDetail = (event, newElement) => {
        event.preventDefault();
        setAttachment(undefined);
        let msg = {...mensaje,
            idThread: newElement.idThread,
            remitente: newElement.idRemitente,
            fechaCreacion: newElement.fechaCreacion,
            mensaje: newElement.mensaje,
            attach: newElement.attach
        };
        setMensaje(msg);
        setShowMessage(true);
        handleLoadFiles(msg);
    }

    

    const sortDateMensajes = () => {
        if(sortBy){
            let totalMns = totalMensajes?.map(obj => {return {...obj, date: new Date(obj.fechaCreacion)}}).sort((a, b) => b.date - a.date);
            let mns = totalMns.slice(0, 10);
            setMensajes(mns);
            setSortBy(!sortBy);
        }else{
            let mns = totalMensajes.slice(0, 10);
            setMensajes(mns);
            setSortBy(!sortBy);
        }
    }

    const loadPageData = useCallback (async () => {


        let buildUrl = `/gestiones/gestionById/${idgestion}`;
        
        instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
        
        await instance.get(buildUrl).then(function(resp) {
            if(error) setError(false);
            let messages = [];
            messages = resp.data.data.thread;
            setTotalMensajes(messages);
            setMensajes(messages?.map(obj => {return {...obj, date: new Date(obj.fechaCreacion)}}).sort((a, b) => b.date - a.date));
            setMensaje(messages[0]);
            setTicketData({...ticketData,
                idGestion: resp.data.data.idGestion,
                tipoGestion: resp.data.data.tipoGestion ? resp.data.data.tipoGestion : "Sin definir",
                titulo: resp.data.data.titulo ? resp.data.data.titulo : "Sin definir",
                fechaCreacion: resp.data.data.fechaCreacion,
                estado: resp.data.data.status ? resp.data.data.status : "Sin definir"
            });
        })
        .catch(function(resp) {
            setError(true);
            setOpenResponse({open: true, msg: "Se ha producido un error inesperado, vuelve a intentarlo más tarde."});
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    return(
        <>
            {loading ? 
                    (
                        <Box p={3} align="center">
                            <CircularProgress />
                        </Box>
                    ) : (
                    <>
                        {!error ?
                            <>
                                {mensajes.length > 0  ? (
                                    <>
                                        <div className={"cdc-mensajes-options status " + ticketData.estado}>
                                            <p style={{color: "#fff"}}>{ticketData.estado === "Close" ? "Cerrado" :
                                                ticketData.estado === "Pending" ? "Pendiente": "Nueva"}</p>
                                        </div>
                                        <div className="cdc-gestion">
                                            <div className="cdc-gestion-propertys">
                                                <div className="cdc-gestion-property">
                                                        <h4>Tipo de Gestión: </h4>
                                                        <p>{ticketData.tipoGestion}</p>
                                                </div>
                                                <div className="cdc-gestion-property">
                                                    <h4>Fecha de Creación: </h4>
                                                    <div className="cdc-gestion-property-time">
                                                        <p style={{marginRight: "5px"}}>{ticketData.fechaCreacion ? format(new Date(ticketData.fechaCreacion), "dd/MM/yyyy") : "Fecha sin definir"}</p>
                                                        <p>{ticketData.fechaCreacion ? format(new Date(ticketData.fechaCreacion), "HH:mm") : "Hora sin definir"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cdc-gestion-property">
                                                <h4>Título: </h4>
                                                <p>{ticketData.titulo}</p>
                                            </div>
                                        </div>
                                        <Dialog open={open} onClose={handleCloseCancel} className="gestiones-dialog">
                                                <DialogContent className="gestiones-dialog-content">
                                                    <DialogActions className="gestiones-dialog-close">
                                                        <a href="#" onClick={handleCloseCancel} variant="contained">X</a>
                                                    </DialogActions>
                                                    <div className="gestiones-dialog-content-form">
                                                            <DialogContentText className="gestiones-dialog-content-title">
                                                                Nuevo Mensaje
                                                            </DialogContentText>
                                                            <p className="gestiones-dialog-content-subtitle">Rellena los campos necesarios para generar un mensaje y añadirlo al hilo actual:</p>
                        
                                                            <div className="cdc-contact-input">
                                                                <label htmlFor="mensaje">MENSAJE*</label>
                                                                <textarea
                                                                style={{width: "100%"}}
                                                                name="mensaje"
                                                                onChange={(e) => {
                                                                    setFormDataMensaje(e.target.value);
                                                                }}
                                                                className={errors.errorMensaje ? "cdc-red-flag": ""}
                                                                />
                                                                {/*errors.errorMensaje && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorMensajeMsg}</span>*/}
                                                            </div>
                                                    </div>
                                                    {files.length !== 1 ? 
                                                        <div>
                                                            {loadingCompress ?
                                                                <Box p={3} align="center">
                                                                    <CircularProgress />
                                                                    <p>Comprimiendo imagen...</p>
                                                                </Box>
                                                            : 
                                                            <>
                                                                <button type="button" style={{display: "flex", position: "relative", backgroundColor: "transparent", border: "none", padding: 0}}>
                                                                    <input id="cdc-ticket-upload" type="file" name="cdc-ticket-upload" onChange={handleChangeFile} onClick={(event)=> { event.target.value = null}} style={{visibility: "hidden"}}/>
                                                                    <label htmlFor="cdc-ticket-upload" className="cdc-backoffice-file-label">
                                                                        <DriveFolderUploadIcon style={{fill: "#304D30"}} alt={"Subir nuevo documento"}/>
                                                                        <span style={{marginLeft: "5px", color: "#304D30"}}>Añadir archivo</span>
                                                                    </label>
                                                                </button>
                                                                {errors.errorFile && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorFileMsg}</span>}
                                                            </>
                                                            }
                                                        </div>
                                                    :
                                                        <div style={{marginBottom: "12px"}}>
                                                            {files.map((file, index) => {
                                                                return (
                                                                    <div className="flex-container align-center" style={{backgroundColor: "rgba(0, 0, 0, 0.04)", justifyContent: "space-between", padding: "0 5px"}}>
                                                                        <div key={"archivo-adjunto-" + index} className="flex-container align-center">
                                                                            <FilePresentIcon style={{ fill: '#304D30', width: '30px', height: '30px' }}/>
                                                                            <p>{file.name}</p>
                                                                        </div>
                                                                        <button className="cdc-backoffice-file-delete" type="button" style={{marginLeft: "5px"}} onClick={() => handleDeleteFile(file.name)}><DeleteOutlineIcon style={{ fill: '#304D30'}}/></button>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    }
                                                    <DialogActions sx={{marginBottom: "20px", padding: "0", marginTop: "20px"}}>
                                                        <Button disabled={loadingSend || loadingCompress} className="cdc-mensaje-button" variant="contained" onClick={handleSubmitCreate}>{loadingSend ? "Enviando...": "Enviar"}</Button>
                                                    </DialogActions>
                                                </DialogContent>
                                            </Dialog>

                                        {showMessage === true ? (
                                            <>
                                            <h3>Detalle del Mensaje:</h3>
                                            <div className="cdc-mensaje">
                                                <div className="cdc-mensaje-property">
                                                    <div className="cdc-mensaje-property-sender">
                                                        <h4>Remitente: </h4>
                                                        <p>{mensaje.remitente}</p>
                                                    </div>
                                                    <div>
                                                        <h4>Fecha Creación: </h4>
                                                        <div className="cdc-mensaje-property-time">
                                                            <p style={{marginRight: "5px"}}>{format(new Date(mensaje.fechaCreacion), "dd/MM/yyyy")}</p>
                                                            <p>{format(new Date(mensaje.fechaCreacion), "HH:mm")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cdc-mensaje-property-mensaje">
                                                    <h4>Mensaje: </h4>
                                                    <p>{mensaje.mensaje}</p>
                                                </div> 
                                                {attachment !== undefined && 
                                                    <div>
                                                        <Button className="cdc-mensaje-attach" label="Ver archivo" onClick={() => setOpenPreview(true)}>Ver archivo</Button>
                                                        {typeFile !== "pdf" &&
                                                            <Link className="cdc-mensaje-attach" href={attachment} underline="none" download>Descargar adjunto</Link>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        {ticketData.estado !== "Close" && <Button  className="cdc-mensaje-button" variant="contained" onClick={handleOpenDialog}> Responder </Button>}
                                        
                                        <ul className="cdc-mensajes-list">
                                            <h3>Hilo de Mensajes</h3>
                                            <div className="cdc-mensajes-options">
                                                <button className="cdc-mensajes-options-sort" type="button" onClick={sortDateMensajes}>
                                                    <figure>
                                                        <img src={ordenar} alt="Ordenar mensajes" />
                                                    </figure>
                                                    <p>Ordenar fecha asc/desc</p>
                                                </button>
                                            </div>
                                            
                                            {mensajes.map((newElement, index) => {
                                                return (
                                                    <>
                                                        <li className={"cdc-mensajes-container"} key={"mensajes-" + index} onClick={(e) => {handleMensajeDetail(e, newElement)}}>
                                                            <Box className="cdc-mensajes-container-right">
                                                                <div>
                                                                    <p className="cdc-mensajes-date">{format(new Date(newElement.fechaCreacion), "dd/MM/yyyy")}</p>
                                                                    <h2 className="cdc-mensajes-title">{newElement.mensaje}</h2>
                                                                    <p className="cdc-mensajes-summary">{newElement.idRemitente}</p>
                                                                </div>
                                                                <p className="cdc-mensajess-date cdc-mensajes-hhmm">{format(new Date(newElement.fechaCreacion), "HH:mm")}</p>
                                                            </Box>
                                                        </li>
                                                    </>
                                                )
                                            })}
                                        </ul>

                                    </>
                                ) : (
                                    <div className="cdc-raffles-notfound">No se han encontrado mensajes para esta gestión.</div>
                                )
                                }
                            </>
                            :
                            <div className="cdc-error-call">Se ha producido un error al intentar obtener la información. Vuelva a intentarlo más tarde.</div>
                        }
                        <PreviewDoc open={openPreview} setOpen={setOpenPreview} document={attachment} type={typeFile}/>
                        <ConfirmDialog open={openResponse} setOpen={setOpenResponse} />
                    </>
                )
            }
            <div className="doc-counter" style={{display: 'none'}} ref={countInput}>1</div>                    
        </>
    );
}