import {Button, CssBaseline, Box, Typography, Container} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import welcomeImage from "../../assets/fondo_bloque1.png";
import logo from '../../assets/mobile/logo-wellcome.png';
import IosShareIcon from '@mui/icons-material/IosShare';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';


export default function Install() {

    const navigate = useNavigate();
    

    const navigateToLogin = () => {
        navigate('/login');
    }

    const navigateToRegister = () => {
        navigate('/registro');
    }
    
    return(
        <Container component="main" className="welcome-main">
        <CssBaseline />
        <Box component="form"       
        sx={{
            background: '#FFFFFF',
            height: "100%",
            position: "relative"
        }}
        >
            <div className='welcome-body welcome-body-v2'>
                    <figure className='welcome-body-logo'>
                                <img src={logo} alt={"Imagen Logo Club de Caza"}/>
                            </figure>
                    <Box className="cdc-install-dialog-top">
                        <div className="noPaddingImp">Añadir a la página de inicio</div>
                        
                    </Box>
                    <Box className="cdc-install-dialog-bottom">
                        <div className="cdc-install-dialog-sum">
                            <p>Esta web tiene disponible su uso en App, siendo necesario el empleo de Safari como navegador. Añadela a tu pantalla de inicio para poder usarla y a pantalla completa.</p>
                        </div>
                        <ul className="cdc-install-dialog-ul">
                            <li className="cdc-install-dialog-li">
                                <figure>
                                    <IosShareIcon style={{ fill: '#304D30'}}/>
                                </figure>
                                <p>1) Pulsa el botón "Compartir"</p>
                            </li>
                            <li className="cdc-install-dialog-li">
                                <figure>
                                    <AddBoxOutlinedIcon style={{ fill: '#304D30'}}/>
                                </figure>
                                <p>2) Pulsa en "Añadir a la pantalla de inicio"</p>
                            </li>
                        </ul>
                    </Box>
                    </div>
                </Box>
            </Container>
                
    );

    return(
            <Container component="main" className="welcome-main">
                <CssBaseline />
                <Box component="form"       
                sx={{
                    background: '#FFFFFF',
                    height: "100%",
                    position: "relative"
                }}
                >
                    <div className='welcome-body welcome-body-v2'>
                            <figure className='welcome-body-logo'>
                                <img src={logo} alt={"Imagen Logo Club de Caza"}/>
                            </figure>
                            <Typography component="h1" variant="h2" className="welcome-body-typography">
                            Bienvenido
                            </Typography>
                            <Typography component="h1" variant="body2" className="welcome-body-typography-subtitle">
                                El Club Tierra de Caza le da abre las puertas para disfrutar todas las ventajas que ofrece
                            </Typography>
                            <div className='welcome-body-buttons'>
                                <Button
                                onClick={navigateToLogin}
                                className="welcome-body-buttom"
                                >
                                Entrar
                                </Button>
                                <Button
                                onClick={navigateToRegister}
                                className="welcome-body-buttom"
                                >
                                Registrarse
                                </Button>
                            </div>
                    </div>
                </Box>
            </Container>
    );
}