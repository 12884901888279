import  {Button, CircularProgress, Link, Grid, Box, Typography} from '@mui/material';
import { useAuth } from "../../../firebase/Auth";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Swal from 'sweetalert2';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import OjoAbierto from "../../../assets/mobile/ojo-abierto.svg";
import OjoCerrado from "../../../assets/mobile/ojo-cerrado.svg";

export default function LoginForm() {

    const { signIn } = useAuth();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const upModalError = (error) => {
        Swal.fire({
            icon: "error",
            title: "error",
            text: error,
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: "Aceptar"
        });
    }

    const checkForm = (data) => {
        let error = null;
        if((data.get('email') === "" || data.get('email') === null || data.get('email') === undefined) && (data.get('password') === "" || data.get('password') === null || data.get('password') === undefined)){
            error = "Debes introducir tu correo y contraseña";
        }else if((data.get('email') === "" || data.get('email') === null || data.get('email') === undefined) && (data.get('password') !== "" || data.get('password') !== null || data.get('password') !== undefined)){
            error = "Debes introducir tu correo";
        }else if((data.get('email') !== "" || data.get('email') !== null || data.get('email') !== undefined) && (data.get('password') === "" || data.get('password') === null || data.get('password') === undefined)){
            error = "Debes introducir tu contraseña";
        }
        return error;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let errorsForm = checkForm(data);
        if(!errorsForm){
            let errorsLogin;
            setLoading(true);
            const response = await signIn(data.get('email'), data.get('password'));
            if(response?.login === true){
                navigate("/home");
                console.log("Navega a la Home!");
            }else if(response?.login === false){
                errorsLogin = "Las credenciales introducidas no corresponde con ningún usuario. Por favor, inténtelo de nuevo.";
                upModalError(errorsLogin);
                setLoading(false);
            }else{
                errorsLogin = "Se ha producido un error inesperado, vuelve a intentarlo más tarde.";
                upModalError(errorsLogin);
                setLoading(false);
            }
        }else{
            upModalError(errorsForm);
            setLoading(false);
        }
        
    };

    
    return(
        <>
        {loading ? 
            (<Box className='login-form-progress'>
                <CircularProgress />
            </Box>
            ) 
            :
            (
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: "100%"}} className="login-form">
                    <div className='login-form-container'>
                        <FormControl sx={{ m: 1, marginBottom:"25px"}} variant="standard" className="login-form-container-field">
                            <InputLabel htmlFor="email">EMAIL*</InputLabel>
                            <Input
                                id="email"
                                className='login-form-email'
                                label="Correo electrónico"
                                required
                                name="email"
                            />
                        </FormControl>
                        
                        <FormControl sx={{ m: 1, marginBottom: "50px"}} variant="standard" className="login-form-container-field">
                            <InputLabel htmlFor="password">CONTRASEÑA*</InputLabel>
                            <Input
                                id="password"
                                required
                                className='login-form-passwd'
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    ><img
                                    title={showPassword ? "Ocultar contraseña" : "Mostrar contraseña"}
                                    src={showPassword ? OjoCerrado : OjoAbierto}
                                    alt={"Icono visibilidad"}
                                    />
                                    </IconButton>
                                </InputAdornment>
                                }
                                label="Contraseña"
                            />
                        </FormControl>
                    </div>
                    <Button
                    type="submit"
                    className="login-form-buttom"
                    >
                    Iniciar sesión
                    </Button>
                    <Grid container sx={{ display: 'flex', flexDirection:'column', marginTop: "20px"}}>
                        <Grid item sx={{marginBottom: "16px"}}>
                            <Typography variant="caption" sx={{color: "#828282", fontSize: "14px"}}>
                                ¿Necesita una cuenta?
                            </Typography>
                            <Link href="/registro" variant="body2" sx={{color: "#304D30", marginBottom:"5px", marginLeft:"8px", textDecoration: "none", fontSize: "14px"}}>
                                Regístrate
                            </Link>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption" sx={{color: "#828282", fontSize: "14px"}}>
                                ¿Olvidaste la contraseña?
                            </Typography>
                            <Link href="/olvido-contrasena" variant="body2" sx={{color: "#304D30", marginLeft:"8px", textDecoration: "none", fontSize: "14px"}}>
                                Recuperar
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            )
        }
    </>
        
        
    )
}