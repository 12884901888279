import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useAuth } from '../../firebase/Auth';
import socio from '../../assets/serviciosPWA.svg';
import { Link, useLocation } from 'react-router-dom';


import personalAreaIcon from '../../assets/home/usuario.png';
import sorteosAreaIcon from '../../assets/home/calendario.png';
import tramitesAreaIcon from '../../assets/home/carpeta.png';

import tipoSorteoIcon from '../../assets/home/calendario-add.png';
import misSorteosIcon from '../../assets/home/calendario-ok.png';

import perfilIcon from '../../assets/home/usuario-p.png';
import docIcon from '../../assets/home/archivo.png';

import seguroIcon from '../../assets/home/protegido.png';
import licenciaIcon from '../../assets/home/objetivo.png';


export const Home = () => {

  const { currentUser } = useAuth();

  let location = useLocation();

  useEffect(()=> {
    if (location?.hash) {
      let elem = document.getElementById(location.hash.slice(1))
      if(elem) {
        setTimeout(function(){
          elem.scrollIntoView({behavior: "smooth"})
      }, 500);
      }          
    } else {
      window.scrollTo({top:0,left:0, behavior: "smooth"})
    }
  }, [location])
  
    return (
      <>
        <div className="cdc-home cdc-v2-home-bg">

          {currentUser.tipo?.toLowerCase() === "basic" && 
            (
              <div className="cdc-v3-home-container cdc-v3-home-box">
                <Link to="/hacerse-socio">
                  <div className="cdc-v3-home-box-title cdc-v3-bg-socio">
                    Hacerme socio
                  </div>
                </Link>
              </div>
            )
          }

          <div className="cdc-v4-block-head">
            <img className="cdc-v4-block-img" src={personalAreaIcon} alt={"Área personal"}/>
            <div className="cdc-v4-block-head-title">ÁREA PERSONAL</div>
          </div>

          <div className="cdc-v4-block-actions">
            <div className="cdc-v4-action-item">
              <Link to="/mis-documentos">
                <div><img className="cdc-v4-block-btn-img" src={docIcon} alt={"Documentación"}/></div>
                <div className="cdc-v4-block-btn">
                  Documentación
                </div>
              </Link>
            </div>

            <div className="cdc-v4-action-item">
              <Link to="/mi-perfil">
                <div><img className="cdc-v4-block-btn-img" src={perfilIcon} alt={"Mi perfil"}/></div>
                <div className="cdc-v4-block-btn">
                  Mi perfil
                </div>
              </Link>
            </div>
          </div>

          <div className="cdc-v4-block-head">
            <img className="cdc-v4-block-img" src={sorteosAreaIcon} alt={"Sorteos de caza"}/>
            <div className="cdc-v4-block-head-title">SORTEOS DE CAZA</div>
          </div>

          <div className="cdc-v4-block-actions">
            <div className="cdc-v4-action-item">
              <Link to="/sorteos/reserva">
                <div><img className="cdc-v4-block-btn-img" src={tipoSorteoIcon} alt={"Reservas"}/></div>
                <div className="cdc-v4-block-btn">
                  Reservas
                </div>
              </Link>
            </div>

            <div className="cdc-v4-action-item">
              <Link to="/sorteos/tierraDeCaza">
                <div><img className="cdc-v4-block-btn-img" src={tipoSorteoIcon} alt={"Gratuitos"}/></div>
                <div className="cdc-v4-block-btn">
                  Gratuitos
                </div>
              </Link>
            </div>

            <div className="cdc-v4-action-item">
              <Link to="/sorteos/exclusivo">
                <div><img className="cdc-v4-block-btn-img" src={tipoSorteoIcon} alt={"Exclusivos"}/></div>
                <div className="cdc-v4-block-btn">
                  Exclusivos
                </div>
              </Link>
            </div>

            <div className="cdc-v4-action-item">
              <Link to="/mis-sorteos">
                <div><img className="cdc-v4-block-btn-img" src={misSorteosIcon} alt={"Mis sorteos"}/></div>
                <div className="cdc-v4-block-btn">
                  Mis sorteos
                </div>
              </Link>
            </div>
          </div>

          <div className="cdc-v4-block-head">
            <img className="cdc-v4-block-img" src={tramitesAreaIcon} alt={"Trámites"}/>
            <div className="cdc-v4-block-head-title">TRÁMITES</div>
          </div>

          <div className="cdc-v4-block-actions">
            <div className="cdc-v4-action-item">
              <Link to="/licencias">
                <div><img className="cdc-v4-block-btn-img" src={licenciaIcon} alt={"Licencia"}/></div>
                <div className="cdc-v4-block-btn">
                  Licencia
                </div>
              </Link>
            </div>

            <div className="cdc-v4-action-item">
              <Link to="/seguros">
                <div><img className="cdc-v4-block-btn-img" src={seguroIcon} alt={"Seguro"}/></div>
                <div className="cdc-v4-block-btn">
                  Seguro
                </div>
              </Link>
            </div>
          </div>

          <div className="cdc-v3-home-container cdc-v3-home-box">
            <Link to="/invitar-amigo">
              <div className="cdc-v3-home-box-title cdc-v3-bg-amigo">
                Invita a tus amigos a unirse
              </div>
            </Link>
          </div>


          {currentUser.tipo?.toLowerCase() === "basic" && 
            (
              <Link to="/hacerse-socio">
                <Grid className="cdc-home-servicios">
                  <figure>
                    <img src={socio} alt={"Sección Hacerme socio"}/>
                  </figure>
                  <h1>Hacerme socio</h1>
                  <p>Date de alta ahora y empieza a disfrutar de todas las ventajas del club.</p>
                </Grid>
              </Link>
            )
          }

        </div>
    </>
      );
}