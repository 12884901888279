import { Box, Drawer, Link } from "@mui/material";
import { useAuth } from '../../firebase/Auth';
import { useNavigate } from "react-router-dom";
import pdfLegal from "../../assets/legal/AVISO_LEGAL.pdf";
import pdfContratacion from "../../assets/legal/CONDICIONES_PARTICULARES_DE_CONTRATACION.pdf";
import pdfCondicionesUso from "../../assets/legal/CONDICIONES_GENERALES_DE_USO.pdf";
import pdfRGPD from "../../assets/legal/CERTIFICADO_RGPD-LOPD-GDD.pdf";
import pdfPrivacidad from "../../assets/legal/POLITICA_DE_PRIVACIDAD.pdf";
import pdfLSSICE from "../../assets/legal/CERTIFICADO_LSSICE.pdf";
import { Link as LinkRouter } from "react-router-dom";

export default function LateralMenu({ open, setOpen }) {

    const { logout, currentUser } = useAuth();
    const navigate = useNavigate();
    const handleLinkClick = (pdfSrc) => {
        navigate('/documentos-legales', {state: pdfSrc});
    }

    const handleLogout = async (event) => {
        event.preventDefault();
        logout();
        navigate("/login");
    };
    
    return(
        <Drawer open={open} onClose={() => setOpen(false)} className="cdc-header-lateral">
            <Box>
                <Box className="cdc-header-lateral-title">
                        Club Tierra De Caza
                    </Box>
                <ul className="cdc-header-lateral-links">
                    {currentUser.tipo?.toLowerCase() === "basic" && 
                    (
                        <>
                            <Box component="li" className="container-link">
                                <LinkRouter to="/hacerse-socio">HACERME SOCIO</LinkRouter>
                            </Box>
                        </>
                    )
                    }
                    <Box component="li" className="container-link">
                        <LinkRouter className="homeicon" to="/home">INICIO</LinkRouter>
                    </Box>
                    <Box component="li" className="container-link">
                        <LinkRouter className="profilecircle" to="/mi-perfil">PERFIL</LinkRouter>
                    </Box>
                    <Box component="li" className="container-link">
                        <LinkRouter className="notificacionicon" to="/notificaciones">NOTIFICACIONES</LinkRouter>
                    </Box>
                    <Box component="li" className="container-link">
                        <LinkRouter className="serviciosicon" to="/servicios">SERVICIOS</LinkRouter>
                    </Box>
                    <Box component="li" className="container-link">
                        <LinkRouter className="sorteoicon" to="/sorteos">SORTEOS</LinkRouter>
                    </Box>
                    <Box component="li" className="container-link">
                        <LinkRouter className="hearticon" to="/mis-sorteos">MIS SORTEOS</LinkRouter>
                    </Box>
                    <Box component="li" className="container-link">
                        <LinkRouter className="bookicon" to="/mis-documentos">DOCUMENTACIÓN</LinkRouter>
                    </Box>
                    <Box component="li" className="container-link">
                        <LinkRouter className="bankicon" to="/home/#newsletter-home">SUSCRIPCIÓN</LinkRouter>
                    </Box>
                    <Box component="li" className="container-link">
                        <LinkRouter className="friendicon" to="/invitar-amigo">INVITAR A UN AMIGO</LinkRouter>
                    </Box>
                    <Box component="li" className="container-link">
                        <LinkRouter className="messageicon" to="/contacto">CONTACTO</LinkRouter>
                    </Box>
                    <Box component="li" className="container-link">
                        <LinkRouter className="managementicon" to="/mis-gestiones">MIS GESTIONES</LinkRouter>
                    </Box>
                    <Box component="li" className="container-link">
                        <LinkRouter className="closeicon" to="#" onClick={handleLogout}>CERRAR SESIÓN</LinkRouter>
                    </Box>
                    <Box component="li" className="container-link-legal">
                        <Link className="legalicon" onClick={() => handleLinkClick("https://clubtierradecaza-mail-images.s3.eu-west-1.amazonaws.com/documentos-legales/AVISO_LEGAL.pdf")}>Avisos Legales</Link>
                    </Box>
                    <Box component="li" className="container-link-legal">
                        <Link className="legalicon" onClick={() => handleLinkClick("https://clubtierradecaza-mail-images.s3.eu-west-1.amazonaws.com/documentos-legales/POLITICA_DE_PRIVACIDAD.pdf")}>Política de Privacidad</Link>
                    </Box>
                    <Box component="li" className="container-link-legal">
                        <Link className="legalicon" onClick={() => handleLinkClick("https://clubtierradecaza-mail-images.s3.eu-west-1.amazonaws.com/documentos-legales/CONDICIONES_PARTICULARES_DE_CONTRATACION.pdf")}>Política de contratación</Link>
                    </Box>
                    <Box component="li" className="container-link-legal">
                        <Link className="legalicon" onClick={() => handleLinkClick("https://clubtierradecaza-mail-images.s3.eu-west-1.amazonaws.com/documentos-legales/CONDICIONES_GENERALES_DE_USO.pdf")}>Condiciones Generales de Uso</Link>
                    </Box>
                    <Box component="li" className="container-link-legal">
                        <Link className="legalicon" onClick={() => handleLinkClick("https://clubtierradecaza-mail-images.s3.eu-west-1.amazonaws.com/documentos-legales/CERTIFICADO_RGPD-LOPD-GDD.pdf")}>Certificado RGPD</Link>
                    </Box>
                    <Box component="li" className="container-link-legal">
                        <Link className="legalicon" onClick={() => handleLinkClick("https://clubtierradecaza-mail-images.s3.eu-west-1.amazonaws.com/documentos-legales/CERTIFICADO_LSSICE.pdf")}>Certificado LSSICE</Link>
                    </Box>
                </ul>
            </Box>
        </Drawer>
    );
}

/**
 * <Box component="li" className="container-link">
                        <LinkRouter className="noticiasicon" to="/blogs">BLOG/NOTICIAS</LinkRouter>
                    </Box>
 */