import { Box, Dialog, DialogActions, DialogContent, Stack } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState, useRef, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../firebase/Auth";

export default function PStepThree({position, setPosition, state, setState}) {

    const [open, setOpen] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [pending, setPending] = useState(false);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [ searchParams ] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const initialForm = {
        merchantBase64: null,
        signatureBase64: null,
    };
    
    const [ formState, setFormState ] = useReducer((currForm, newValues) => ({...currForm, ...newValues}), initialForm);
    const cryptojs = require("crypto-js");
    const formRef = useRef(null);
    const { currentUser } = useAuth();

    const handlePrev = (e) => {
        e.preventDefault();
        setPosition(1);
    }

    const handleCloseCancel = () => {
        setOpen(false);
        setOpenError(false);
    }

    useEffect(() => {
        if(validateForm()){
            setSignVariables();
        }

      }, [state]);

    const validateForm = () =>{
        let valid = true;
        /*if(state.iban === undefined || state.iban === ''){
            setErrors(errors => ({...errors, errorIban: true, errorIbanMsg: ""}));
            inputIds = inputIds ? inputIds +",ibanjoinclub" : "ibanjoinclub";
            valid = false;
        }else if(!/^[A-Z]{2}[0-9A-Z]*$/.test(state.iban)){
            setErrors(errors => ({...errors, errorIban: true, errorIbanMsg: "El IBAN no es válido. Por favor, revísalo."}));
            inputIds = inputIds ? inputIds +",ibanjoinclub" : "ibanjoinclub";
            valid = false;
        }*/
        if(state.name === '' || state.surname === '' || state.dni === '' || state.phone === '' || state.address === '' || state.town === '' || state.province === '' || state.zipcode === '' || state.email === ''){
            valid = false;
        }
        
        return valid;
    }

    const handleSubmit = (e) => {
        setSignVariables();
        e.preventDefault();
        window.localStorage.setItem("payment", JSON.stringify(state));
        setErrors({});
        if(validateForm()){
            if (formState.merchantBase64 !== null && formState.signatureBase64 !== null) {
                formRef.current.submit();
            } 
        }
    };


    const setSignVariables = () => {
        setLoading(true);
        let tpvdata={
            "DS_MERCHANT_AMOUNT":"9900",
            "DS_MERCHANT_CURRENCY":"978",
            "DS_MERCHANT_MERCHANTCODE":"97888812",
            "DS_MERCHANT_MERCHANTURL":"https://ulcbbyxid6.execute-api.eu-south-2.amazonaws.com/pro/clubcaza/redsys",
            "DS_MERCHANT_ORDER": menchantOrderGenerator(),
            "DS_MERCHANT_TERMINAL":"1",
            "DS_MERCHANT_TRANSACTIONTYPE":"0",
            "DS_MERCHANT_URLKO":"https://server.tierradecaza.com/hacerse-socio",
            "DS_MERCHANT_URLOK":"https://server.tierradecaza.com/hacerse-socio",
            "DS_MERCHANT_IDENTIFIER":"REQUIRED",
            "DS_MERCHANT_MERCHANTDATA": JSON.stringify(state)
        };

        let merchant_key="5vTr8JM+/oOu4RqMtGRfgFDhuCKwj6GL"
        var merchantWordArray = cryptojs.enc.Utf8.parse(JSON.stringify(tpvdata));
        let baseMerchant = merchantWordArray.toString(cryptojs.enc.Base64)

        // Decode key
        var keyWordArray = cryptojs.enc.Base64.parse(merchant_key);

        // Generate transaction key
        var iv = cryptojs.enc.Hex.parse("0000000000000000");
        var cipher = cryptojs.TripleDES.encrypt(tpvdata.DS_MERCHANT_ORDER, keyWordArray, {
            iv:iv,
            mode: cryptojs.mode.CBC,
            padding: cryptojs.pad.ZeroPadding
        });

        // Sign
        let sign = cryptojs.HmacSHA256(baseMerchant, cipher.ciphertext);
        setFormState({...formState, merchantBase64: baseMerchant, signatureBase64: sign.toString(cryptojs.enc.Base64)});
    };

    
    
    const generateRandomString = () => {
        const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const strLength = 1;
        let randStr  = "";
        for (let i = 0; i <= strLength; i++) {
            let randomNumber = Math.floor(Math.random() * chars.length);
            randStr += chars.substring(randomNumber, randomNumber +1);
        }
        return randStr;
    }

    const menchantOrderGenerator = () =>{
        const today = new Date();
        const dateString = `${today.getFullYear()}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getDate().toString().padStart(2, '0')}${today.getHours().toString().padStart(2, '0')}`;
        const randomNumber = generateRandomString();
        return dateString + randomNumber.toString();
    }
    

    return(
        <>
            <>
                <form style={{display: "none"}} name="from" id="formPayment" action="https://sis.redsys.es/sis/realizarPago" method="POST" ref={formRef}> 
                    <input  name="Ds_SignatureVersion" value="HMAC_SHA256_V1"/>
                    <input  id="Ds_MerchantParameters" name="Ds_MerchantParameters" value={formState.merchantBase64}/>
                    <input  id="Ds_Signature" name="Ds_Signature" value={formState.signatureBase64}/>
                    <input type="submit"/>
                </form>
            </>
            <Box className={position !== 2 && 'hide'}>
                {/*<div className="cdc-joinclub-input">
                    <label htmlFor="iban">IBAN*</label>
                    <input type="text" id="ibanjoinclub" value={state.iban} name="iban" onChange={(e) => {setState(state => ({...state, iban: e.target.value})); setErrors(errors => ({...errors, errorIban: false, errorIbanMsg: ""}));}} required className={errors.errorIban ? "cdc-red-flag": ""}/>
                    {errors.errorIban && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorIbanMsg}</span>}
                </div>*/}   
                {/*<p>¡Introduce el código amigo para recibir puntos! Lorem ipsum...</p>*/}
                <div className="cdc-joinclub-input">
                    <label htmlFor="invCode">CÓDIGO DE AMIGO</label>
                    <input type="text" id="invCodejoinclub" value={state.iviteCode} name="iban" onChange={(e) => {setState(state => ({...state, iviteCode: e.target.value})); setErrors(errors => ({...errors, errorInvCode: false, errorInvCodeMsg: ""}));}} required className={errors.errorInvCode ? "cdc-red-flag": ""}/>
                    {errors.errorInvCode && <span style={{color: "red", fontWeight: "600", fontSize: "14px"}}>{errors.errorInvCodeMsg}</span>}
                </div>
                <Stack spacing={2} direction="row" justifyContent="space-between">
                    <button className="cdc-joinclub-prev" onClick={(e) => handlePrev(e)}>Anterior</button>
                    {!pending &&
                        <button
                        className="cdc-joinclub-next socio-pwa"
                        type="submit"
                        onClick={handleSubmit}
                        >
                        Hacerme socio
                        </button>
                    }
                    {pending &&
                        <button
                        className="cdc-joinclub-next"
                        disabled
                        onClick={handleSubmit}
                        >
                        Enviando...
                        </button>
                    }
                </Stack>
            </Box>
        </>
    );
}